import axios from "axios";
import { Navigate, Outlet } from "react-router-dom";
import { api } from "../../../services/api";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  applyDataPageReload, openPopup
} from "../../../store/slices/userAuthSlice";
import { IAuthRefreshToken } from "../../../store/slices/userAuthSlice/interfaces";
import { refreshTokenThunk } from "../../../store/slices/userAuthSlice/thunks";

interface IErrorJWT {
  headers: {
    Authorization: string;
  };
  data?: any;
  method: "get" | "post" | "delete" | "put";
  url: string;
}

export const PrivateRoute = () => {
  const dispatch = useAppDispatch();

  api.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (
        error.response &&
        error.response.data.type === "expired-token-error"
      ) {
        
        const { payload } = (await dispatch(
          refreshTokenThunk()
        )) as { payload: IAuthRefreshToken };

        if (payload.type === "expired-refresh-token-error") {
          dispatch(openPopup({ title: 'Sessão expirada!', message: ' Sua sessão expirou. Por favor, realize login novamente no sistema.' }));
          return error;
        };

        if (payload.statusCode >= 200 && payload.statusCode <= 299) {
          try {
            return await axios.request(error.config);
          } catch (error) {
            return error;
          }
        }
      } 
      
      return error
    }
  );

  const { isLogged, popup } = useAppSelector((state) => state.user);

  if (!isLogged) {
    const publicToken = sessionStorage.getItem("@data-mea:publicToken");
    const userType = sessionStorage.getItem("@data-mea:usertype");
    const token = sessionStorage.getItem("@data-mea:token");

    if (token) dispatch(applyDataPageReload(token));
    
    return (publicToken || userType === "professional") && token ? (
      <Outlet />
    ) : (
      popup?.isOpen ? <Outlet /> : <Navigate to="/auth/login" />
    );
  } else return <Outlet />;
};
