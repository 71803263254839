import styled from "styled-components";
import { IStyledsProps } from "./interfaces";

interface IHeaderContainerTableStyledProps {
  isTimeLineView?: boolean;
};

const HeaderContainer = styled.div<IHeaderContainerTableStyledProps>`
  width: 100%;

  
  transition: all ease .3s;
  display: flex;

  align-items: center;
  justify-content: center;

  padding: 20px;
  
  height: 50px;
  background-color: ${({ isTimeLineView }) => isTimeLineView ? '#558eff05' : 'var(--color-secondary)'};

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .container-wrapper {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-left: ${({ isTimeLineView }) => isTimeLineView ? '10px' : '5%'};

      font-size: ${({ isTimeLineView }) => isTimeLineView ? '1.2rem' : '.91rem'};
      font-weight:  ${({ isTimeLineView }) => isTimeLineView ? '500' : 'bold'};
      color: ${({ isTimeLineView }) => isTimeLineView ? '#3b9bd2' : 'var(--color-white)'};
    }

    button {
      margin-right: 3%;
      cursor: pointer;
      border: none;
      background-color: transparent;
      text-decoration: underline;

      font-size: 0.9rem;
      font-weight: bold;
     
      color: var(--color-white);
    }
  }
`;

const EmptyBody = styled.div<{ emptyRows: number; headerLenght: number }>`
  height: ${({ emptyRows }) => emptyRows * 33 + "px"};
  width: ${({ headerLenght }) => headerLenght * 200 + "px"};
  background-color: white;
`;

const OverflowWrapper = styled.div`
  overflow-x: auto;
  flex: 1;
  width: 100%;


  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
`;

const TableWrapper = styled.div<{ customWidth?: string; order?: number; isFull?: boolean; isTimeLineView?: boolean }>`
  display: flex;
  
  transition: all ease .3s;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  width: ${({ customWidth }) => customWidth ? customWidth : '100%'};
  max-width: ${({ isTimeLineView }) => isTimeLineView ? '' : '750px'};
  padding: ${({ isTimeLineView }) => isTimeLineView && '0px 20px'};
  margin: ${({ isTimeLineView }) => !isTimeLineView && '0px 20px'};

  border: ${({ isTimeLineView }) => isTimeLineView ? 'solid 1px #eaeaea65' : 'solid 1px #eaeaea'};

  min-width: ${({ isFull, isTimeLineView }) => {
    if (isTimeLineView) return '100%';
    else if (isFull) return '96%';
    return 'auto';
  }};

  
  border-radius: 15px;
  
  box-shadow: ${({ isTimeLineView }) => isTimeLineView ? 'none' : '2px 2px 2px rgba(0, 0, 0, .06)'};

  order: ${(props) => props.order};

  &:hover {
    #infoIcon {
      display: initial;
    }
  }
  
    @media (max-width: 1250px) {
      width: 45%;
    };

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    max-width: '100%';
  };
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: #ffffff;
`;

const ContainerHeaderTableRows = styled.div`
  display: flex; 
  flex-direction: column;
`;

const TableHeaderCell = styled.div<{ styled?: IStyledsProps, isTimeLineView?: boolean }>`

  transition: all ease .3s;
  display: flex;
  width: ${({ styled }) => styled?.isRowRresentation ? '130px' : '100%'};
  max-width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};
  padding: 10px;

  height: ${({ styled }) => styled?.isRowRresentation ? '55px' : ''};
  border-right: solid 1px #eaeaea;
  border-bottom: ${({ styled }) => styled?.isRowRresentation ? 'solid 1px #eaeaea' : ''};
  
  background-color: ${({ isTimeLineView }) => isTimeLineView ? '#558eff20' : '#558EFF40'};
  
  color: #25282b;
  font-weight: bold;
`;

const TableBody = styled.div<{ emptyRows: number, isFull?: boolean, isRowRresentation?: boolean, styled?: IStyledsProps }>`
  display: flex;
  
  transition: all ease .3s;
  flex-direction: column;
  width: ${({ styled, isFull, isRowRresentation }) => styled?.width ? `${styled.width}px` : (!isFull && isRowRresentation ? '78.4%' : '100%')};
  min-height: 164px; /* defina uma altura fixa para a tabela */
  background-color: white;
`;

const ContainerModality = styled.div<{ backgroundColor: string, decreaseTextOpacity?: boolean; }>`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: ${({ backgroundColor }) => backgroundColor};
    opacity: ${({ decreaseTextOpacity }) => {
      if (decreaseTextOpacity) return `30%`;
    }};
`;

const TableRow = styled.div<{ rowIndex?: number, styled?: IStyledsProps; isSelected?: boolean; }>`
  display: flex;
  transition: all ease .3s;
  
  :nth-of-type(even) {
    > div {
      background-color: white;
      border: ${({ isSelected }) => isSelected ? 'solid #25282b25 1.5px' : ''};
    }
  }
  
  :nth-of-type(odd) {
    > div {
      border: ${({ isSelected }) => isSelected ? 'solid #25282b25 1.5px' : ''};
      background-color: var(--color-secondary-light);
    }
  }
`;

const TableBodyExtraCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  
  transition: all ease .3s;

  transition: all ease .3s;
  width: 100%;

  font-size: 14px;
  white-space: nowrap;
  
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  padding: 15px;

`;


const TableBodyCellExtraAttributes = styled.div<{ styled?: IStyledsProps, isIcon?: boolean; isRowRresentation?: boolean; isExpandExtraAttributes?: boolean; isSelected?: boolean; }>`

transition: all ease .3s;
  font-size: 14px;
  white-space: nowrap;

  align-items: flex-start;
  display: ${({ isExpandExtraAttributes }) => isExpandExtraAttributes ? 'flex' : 'none'} ;
  justify-content: ${({ isIcon }) => isIcon ? 'center' : ''};

  width: 100%;
  
  max-width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};

  overflow: hidden;
  text-overflow: ellipsis;

  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  button {
    border: none;
    background-color: transparent;
    text-decoration: underline;

    font-weight: bold;
    color: #25282b;
    padding: 0px;
  }

  a {
    cursor: pointer;

    font-size: 0.9rem;

    i {
      margin-left: 5px;
    }
  }
`;

const TableBodyCell = styled.div<{ styled?: IStyledsProps, isIcon?: boolean; isRowRresentation?: boolean; decreaseTextOpacity?: boolean; isSelected?: boolean; }>`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  
  transition: all ease .3s;

  color: ${({ decreaseTextOpacity }) => decreaseTextOpacity ? '#00000035' : '#000000'};
  
  align-items: flex-start;
  display: flex;
  justify-content: ${({ isIcon }) => isIcon ? 'center' : ''};

  width: 100%;
  flex: ${({ isRowRresentation }) => isRowRresentation ? '' : 1};
  max-width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};

  overflow: hidden;
  text-overflow: ellipsis;

  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  button {
    border: none;
    background-color: transparent;
    text-decoration: underline;

    font-weight: bold;
    color: #25282b;
    padding: 0px;
  }

  a {
    cursor: pointer;

    font-size: 0.9rem;

    i {
      margin-left: 5px;
    }
  }
`;

const Pagination = styled.div<{ isTimeLineView?: boolean }>`
  display: flex;
  width: 100%;
  height: 65px;
  justify-content: flex-end;
  
  transition: all ease .3s;
  align-items: end;
  
  border-radius: 0px 0px 15px 15px;

  border: ${({ isTimeLineView }) => isTimeLineView ? '' : 'solid 1px #eaeaea'};

  border-top: solid 1px #ececec;

  background-color: #fff;
  padding: 20px;
`;

const Button = styled.button`
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  
  transition: all ease .3s;
  background: transparent;
  color: #000;
  cursor: pointer;

  &:disabled {
    /* background-color: #ccc; */
    color: #ccc;
    cursor: not-allowed;
  }
`;

const PageNumber = styled.div`
  margin: 2px;
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const TitleContainer = styled.div<{ isTimeLineView?: boolean }>`
  display: flex;
  flex: 1;
  gap: 5px;

  text-align: center;
  align-items: center;

  
  transition: all ease .3s;
  height: 100%;

  i {
    color: ${({ isTimeLineView }) => isTimeLineView ? '#3b9bd2' : 'white'};
    padding-bottom: 11px;
    font-size: 1rem;
  }
`;

export { Button, ContainerHeaderTableRows, ContainerModality, ContainerTitle, EmptyBody, HeaderContainer, OverflowWrapper, PageNumber, Pagination, TableBody, TableBodyCell, TableBodyCellExtraAttributes, TableBodyExtraCell, TableHeader, TableHeaderCell, TableRow, TableWrapper, TitleContainer };

