import styled from "styled-components";

const StyledUserCardContainer = styled.div`
  // Mobile version
  width: 300px;
  max-height: 250px;

  border-radius: 10px;

  margin: 10px;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);

  cursor: pointer;
`;

const StyledUserCardHeader = styled.div`
  background-color: #0000001f;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px 15px 0px 0px;

  height: 100px;

  img {
    border-radius: 100%;
    width: 90px;
    height: 90px;

    margin-left: 10px;
  }

  h1 {
    font-size: 1.1rem;
    text-align: center;
  }
`;

const StyledUserCardDataContainer = styled.div`
  background-color: var(--color-background);
  border-radius: 0px 0px 15px 15px;

  .listUser-list {
    padding: 5px;
    .listUser-item {
      padding: 5px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        p {
          margin-left: 10px;
          font-size: 0.9rem;
          width: 100px;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          font-size: 0.9rem;

          width: 150px;
        }
      }
    }
  }
`;

export { StyledUserCardContainer, StyledUserCardDataContainer, StyledUserCardHeader };

