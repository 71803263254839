import styled from 'styled-components';
import { IScrollStyledProps } from './interfaces';

export const Scroll = styled.div<{ sx?: IScrollStyledProps }>`
    width: 100%;

    overflow: ${({ sx }) => sx?.scrollDirection === 'default' && 'scroll'};
    overflow-y: ${({ sx }) => sx?.scrollDirection === 'horizontal' && 'scroll'};
    overflow-x: ${({ sx }) => sx?.scrollDirection === 'vertical' && 'scroll'};

    ::-webkit-resizer {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: ${({ sx }) => (sx?.scrollSize ? sx?.scrollSize : '10px')};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: ${({ sx }) =>
            sx?.scrollBorderRadius ? sx?.scrollBorderRadius : '50px'};
        background: ${({ sx }) =>
            sx?.scrollColor ? sx.scrollColor : '#c3c3c3'};
    }

    &::-webkit-scrollbar-track {
        background: ${({ sx }) =>
            sx?.scrollBackGround ? sx.scrollBackGround : 'transparent'};
    }
`;
