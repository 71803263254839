import React, { useEffect, useState } from "react";
import { useClientConfig } from "../../../hooks/useClientConfig";
import { ITableContainerProps } from "../../../pages/Summary/components/TableContainer";
import { TableSkeletons } from "../../../pages/Summary/components/TableSkeletons";
import {
  IReqDataTables,
  IconTypesTable,
  RowsTypeInterface,
} from "../../../providers/Requests/types";
import { api } from "../../../services/api";
import { capitalizeText } from "../../../utils/capitalizeText";
import { useSettingsContext } from "../../../utils/useLocalStorage/settingsContext";
import { Tooltip } from "../../Tooltip";
import { Icon } from "../../icon";
import EmptyTable from "../EmptyTable";
import { DescriptionCell } from "./components/cells/descriptionCell";
import {
  Button,
  ContainerHeaderTableRows,
  ContainerModality,
  ContainerTitle,
  HeaderContainer,
  OverflowWrapper,
  PageNumber,
  Pagination,
  TableBody,
  TableBodyCell,
  TableBodyCellExtraAttributes,
  TableBodyExtraCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  TitleContainer,
} from "./style";

export type Row = {
  type: string;
  value: string;
  width?: number;
  label?: string;
  exam?: string;
};

type Props = {
  header: Array<{ type: string; value: string; width: number; label?: string }>;
  rows: Row[][];
  title: string;
  req: IReqDataTables;
  isTimeLineView?: boolean;
  subtitle: string;
  openModal: () => void;
  handleOpenScheduleModal: (cell: RowsTypeInterface) => void;
  handleOpenExamResult: (id: string, title: string) => void;
  handleDownloadFile: (link: string) => void;
  setLoadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  positionOnLayout: number;
  iconType: IconTypesTable;
  isFull?: boolean;
  isRowRresentation?: boolean;
  sx?: ITableContainerProps;
  encounterId?: string;
  setLoadedItemsCount?: React.Dispatch<React.SetStateAction<number>>;
};

const renderTableRows = (
  rows: Row[],
  handleOpenScheduleModal: (cell: RowsTypeInterface) => void,
  handleOpenExamResult: (id: string, title: string) => void,
  handleDownloadFile: (link: string) => void,
  indexLine: number,
  isRowRresentation?: boolean,
  ItemsPerPage?: number,
  headerDataTable?: {
    iconType: IconTypesTable;
    title: string;
    subtitle: string;
  },
  isTimeLineView: boolean = false,
  setIsExpandAttr?: React.Dispatch<React.SetStateAction<boolean[]>>,
  isExpandAttr?: boolean[]
) => {
  if (isRowRresentation) {
    for (
      let index = rows.length;
      index <= (ItemsPerPage ? ItemsPerPage : rows.length);
      index++
    ) {
      rows.push({ type: "text", value: "" });
    }
  }

  if (isRowRresentation) {
    rows = rows.map((row) => {
      if (row.type === "blank") row.type = "text";
      return row;
    });
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { examSchedule } = useClientConfig();

  const toogleExpandExtraAttributes = () => {
    if (setIsExpandAttr) {
      setIsExpandAttr((prev) => {
        return prev.map((item, index) => {
          if (index === indexLine) item = !item;
          else item = false;
          return item;
        });
      });
    }
  };

  const extraRowsRender: JSX.Element[] = [];

  const rowRenders = rows.map((cell, index) => {
    if (cell.type === "isExtraAttributes") {
      extraRowsRender.push(
        <div
          key={`_${index}`}
          style={{
            display: "flex",
            gap: "5px",
            flexWrap: "wrap",
            backgroundColor: "inherit",
            width: "45%",
          }}
        >
          <strong style={{ fontSize: "14px" }}>{cell.label}</strong>{" "}
          <p style={{ fontSize: "13.9px", color: "#00000099" }}>{cell.value}</p>
        </div>
      );
    }

    return (
      <>
        {cell.type === "modality" && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "solid 1px #eaeaea",
              height: isTimeLineView ? "55px" : "auto",
            }}
            isIcon={true}
            key={index}
          >
            <Tooltip
              // @ts-ignore
              description={`Modalidade de Atendimento: ${cell.value.atendModality}` || "Expandir"}
            >
              <ContainerModality
                // @ts-ignore
                backgroundColor={cell.value.background}
                decreaseTextOpacity={
                  !!(
                    isExpandAttr &&
                    isExpandAttr.find(
                      (isExpand, index) =>
                        isExpand === true && index !== indexLine
                    )
                  )
                }
              >
                <Icon
                  handleClick={() => toogleExpandExtraAttributes()}
                  cursor="pointer"
                  customStyleds={{ fontSize: "0.8rem" }}
                  //@ts-ignore
                  color={cell.value.color}
                  type="solid"
                  //@ts-ignore
                  icon={cell.value.icon}
                />
              </ContainerModality>
            </Tooltip>
          </TableBodyCell>
        )}
        {cell.type === "text" && (
          <TableBodyCell
            decreaseTextOpacity={
              !!(
                isExpandAttr &&
                isExpandAttr.find(
                  (isExpand, index) => isExpand === true && index !== indexLine
                )
              )
            }
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            style={{
              borderRight: "solid 1px #eaeaea",
              display: isRowRresentation ? "flex" : "",
              justifyContent: isRowRresentation ? "center" : "",
              height: isTimeLineView ? "55px" : "auto",
            }}
            key={index}
          >
            <>
              {isRowRresentation ? (
                indexLine === 0 ? (
                  <Tooltip description={cell.label as string}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: isTimeLineView ? "13.5px" : "14.5px",
                        height: isTimeLineView ? "10px" : "35px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection:
                          (cell.label?.length as number) > 2 ? "column" : "row",
                      }}
                    >
                      {capitalizeText(cell.value)}
                    </p>
                  </Tooltip>
                ) : (
                  <p
                    style={{
                      gap: "2.5px",
                      fontWeight: "bold",
                      fontSize: "14px",
                      height: isTimeLineView ? "10px" : "35px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: cell?.width != null ? cell.width : "100px",
                      flexDirection:
                        (cell.label?.length as number) > 2 ? "column" : "row",
                    }}
                  >
                    {capitalizeText(cell.value)}
                    {(cell.label?.length as number) > 2 ? (
                      <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                        {capitalizeText(cell.label as string)}
                      </span>
                    ) : (
                      <span
                        style={{
                          marginTop: "4px",
                          fontWeight: "bold",
                          fontSize: "11px",
                        }}
                      >
                        {capitalizeText(cell.label as string)}
                      </span>
                    )}
                  </p>
                )
              ) : (
                <>
                  {cell.value.length > 100 ? (
                    <DescriptionCell
                      headerDataTable={headerDataTable}
                      row={rows}
                    />
                  ) : (
                    <p>{capitalizeText(cell.value)} </p>
                  )}
                </>
              )}
            </>
          </TableBodyCell>
        )}
        {cell.type === "exam-schedule" && examSchedule && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            key={index}
            isIcon={true}
          >
            <Tooltip
              positionInitial="row-reverse"
              description="Solicitação de Agendamento"
            >
              <Icon
                cursor="pointer"
                size="1.5x"
                handleClick={() => handleOpenScheduleModal(cell)}
                color="#558eff"
                type="duotone"
                icon="calendar-clock"
              />
            </Tooltip>
          </TableBodyCell>
        )}
        {cell.type === "exam-result-details" && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            key={index}
            isIcon={true}
          >
            <Tooltip
              positionInitial="row-reverse"
              description="Visualizar o Resultado do Exame"
            >
              <Icon
                cursor="pointer"
                size="1.5x"
                handleClick={() =>
                  handleOpenExamResult(cell.value, cell.exam as string)
                }
                color="#558eff"
                type="duotone"
                icon="poll-h"
              />
            </Tooltip>
          </TableBodyCell>
        )}
        {cell.type === "link" && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            isIcon={true}
            key={index}
          >
            <Tooltip positionInitial="row-reverse" description="Ver resultado">
              <Icon
                handleClick={() => handleDownloadFile(`${cell.value}`)}
                cursor="pointer"
                customStyleds={{ fontSize: "1.20rem" }}
                color="#558eff"
                type="regular"
                icon="link"
              />
            </Tooltip>
          </TableBodyCell>
        )}
        {cell.type === "exam-result" && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            isIcon={true}
            key={index}
          >
            <Tooltip
              positionInitial="row-reverse"
              description="Resultado do Exame"
            >
              <Icon
                handleClick={() =>
                  handleDownloadFile(`${cell.value}&typeView=inline`)
                }
                cursor="pointer"
                customStyleds={{ fontSize: "1.30rem" }}
                color="#558eff"
                type="solid"
                icon="file"
              />
            </Tooltip>
          </TableBodyCell>
        )}
        {cell.type === "blank" && null}
        {cell.type === "buttonExpand" && (
          <TableBodyCell
            isRowRresentation={isRowRresentation}
            styled={{ width: cell.width }}
            isIcon={true}
            key={index}
          >
            <Tooltip
              positionInitial="row-reverse"
              description={cell.label || "Expandir"}
            >
              <Icon
                handleClick={() => toogleExpandExtraAttributes()}
                cursor="pointer"
                customStyleds={{ fontSize: "1.40rem" }}
                color="#558eff"
                type="duotone"
                icon={
                  isExpandAttr && isExpandAttr[indexLine]
                    ? "chevron-circle-up"
                    : "chevron-circle-down"
                }
              />
            </Tooltip>
          </TableBodyCell>
        )}
      </>
    );
  });

  return (
    <>
      {rowRenders}
      {extraRowsRender && extraRowsRender.length >= 1 && (
        <TableBodyCellExtraAttributes
          isSelected={!!(isExpandAttr && isExpandAttr[indexLine])}
          isExpandExtraAttributes={
            isExpandAttr ? isExpandAttr[indexLine] : false
          }
        >
          <TableBodyExtraCell>{extraRowsRender}</TableBodyExtraCell>
        </TableBodyCellExtraAttributes>
      )}
    </>
  );
};

interface IHeader {
  type: string;
  value: string;
  width: number;
  label?: string | undefined;
}

interface IMetaPaginationProps {
  total?: number;
  lastPage?: number;
  currentPage?: number;
  perPage?: number;
  prev?: number;
  next?: number;
}

const Table: React.FC<Props> = ({
  header,
  isRowRresentation,
  rows,
  title,
  subtitle,
  openModal,
  handleOpenScheduleModal,
  handleOpenExamResult,
  handleDownloadFile,
  setLoadingFile,
  positionOnLayout,
  isFull,
  iconType,
  req,
  encounterId,
  isTimeLineView = false,
  setLoadedItemsCount,
  sx,
}) => {
  const [isLoading, setIsLoading] = useState(req.isLoading);
  const [tableRows, setTableRows] = useState<Row[][]>([]);
  const [tableHeader, setTableHeader] = useState<IHeader[]>([]);

  const tableSelected = window.document.getElementById('table');
  let WidthTable = isFull && tableSelected ? tableSelected.clientWidth : 600;
  let ItemsPerPage = 0; 

  const [tableMetaPagination, setTableMetaPagination] =
    useState<IMetaPaginationProps>({
      total: 1,
      lastPage: 1,
      currentPage: 1,
      perPage: isRowRresentation ? Math.floor(WidthTable / 125) * 8 : 5,
      prev: undefined,
      next: undefined,
    });

  const getTableData = async (currentPage?: number) => {
    setIsLoading(true);

    try {
      let dataURL = "";

      if (req.params)
        dataURL = `/${req.datasource}/${req.publicToken}?${req.params}`;
      else if (req.tableCode)
        dataURL = `/${req.datasource}/${req.publicToken}/${req.tableCode}`;
      else dataURL = `/${req.datasource}/${req.publicToken}`;

      const { data } = await api.get(dataURL, {
        params: {
          encounterId: isTimeLineView ? encounterId : undefined,
          itemsPerPage: tableMetaPagination.perPage,
          currentPage: currentPage || 1,
          ...req.query,
        },
      });

      setTableHeader(data.headers);
      setTableRows(data.rows);

      if (data.meta) setTableMetaPagination(data.meta);

      if (setLoadedItemsCount) {
        setLoadedItemsCount((prevState) => prevState + 1);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getTableData();
  }, [req]);

  const [currentPage, setCurrentPage] = useState(0);

  if (
    isRowRresentation &&
    tableRows !== undefined &&
    tableRows[0] !== undefined
  ) {
    let somaDasRows = 0;
    let widthFirstColumn = 0;

    tableRows[0].forEach((row, index) => {
      if (row.width && index !== 0) somaDasRows = somaDasRows + row.width;
      else if (index === 0) widthFirstColumn = row.width ?? 0;
    });

    ItemsPerPage =
      (WidthTable - widthFirstColumn) /
        (somaDasRows / (tableRows[0]?.length - 1)) -
      1;
  }

  const startIndex = currentPage * (isRowRresentation ? ItemsPerPage : 5);
  const endIndex = startIndex + (isRowRresentation ? ItemsPerPage : 5);

  let rowsToShow: Row[][] = [];

  if (tableRows)
    rowsToShow = isRowRresentation
      ? tableRows.map((row) => row.slice(startIndex, endIndex))
      : tableRows.slice(startIndex, endIndex);
  else rowsToShow = [];

  const totalPages = tableRows
    ? Math.ceil(
        isRowRresentation
          ? (tableRows[0]?.length - 1) / ItemsPerPage
          : tableRows?.length / 5
      )
    : 1;

  const emptyRows = tableRows
    ? Math.max(
        0,
        (1 + currentPage) * 5 -
          (isRowRresentation ? tableRows[0]?.length : tableRows?.length)
      )
    : 1;

  const { sortTablesWithoutData } = useSettingsContext();

  const notExistDataTable =
    (!tableRows || tableRows?.length === undefined || tableRows?.length <= 0) &&
    !isLoading;

  const headerDataTable = {
    iconType,
    title,
    subtitle,
  };

  const viewTableToogle = () => {
    if (!isTimeLineView) return true;

    const listTablesToOmitted = [
      "encounter",
      "allergy",
      "comorbities",
      "condition",
    ];

    const isTableToOmitted = listTablesToOmitted.find((tableDataSource) =>
      req.datasource.includes(tableDataSource)
    );

    return !isTableToOmitted && !notExistDataTable;
  };

  const [isExpandAttr, setIsExpandAttr] = useState<boolean[]>([false]);

  useEffect(() => {
    const toogleExpand = rowsToShow.map(() => false);
    setIsExpandAttr(toogleExpand);
  }, [tableRows]);

  return (
    <>
      {(isTimeLineView || !isTimeLineView) && viewTableToogle() && (
        <TableWrapper
          id='table'
          isFull={isFull}
          isTimeLineView={isTimeLineView}
          order={
            notExistDataTable && sortTablesWithoutData === "Bottom"
              ? positionOnLayout
              : -9999
          }
        >
          <HeaderContainer isTimeLineView={isTimeLineView}>
            <div className="container-wrapper">
              <ContainerTitle>
                <Icon
                  color={isTimeLineView ? "#3b9bd2" : "#ffffff"}
                  icon={iconType}
                  size="1.5x"
                  type="solid"
                />
                <TitleContainer isTimeLineView={isTimeLineView}>
                  <h1>{title}</h1>
                  <Tooltip description={subtitle}>
                    <Icon cursor="pointer" type="light" icon={"info-circle"} />
                  </Tooltip>
                </TitleContainer>
              </ContainerTitle>
              {!isTimeLineView && tableRows && tableRows?.length > 0 && (
                <Tooltip
                  positionInitial={"row-reverse"}
                  description="Visualizar em Tela Cheia"
                >
                  <Icon
                    cursor="pointer"
                    handleClick={openModal}
                    type="solid"
                    color={isTimeLineView ? "#3b9bd2" : "#ffffff"}
                    icon={"expand-alt"}
                  />
                </Tooltip>
              )}
            </div>
          </HeaderContainer>

          {notExistDataTable && <EmptyTable title={title} />}

          {isLoading && <TableSkeletons />}

          {tableRows && tableRows?.length >= 1 && !isLoading && (
            <>
              <OverflowWrapper>
                {!isRowRresentation ? (
                  <>
                    <TableHeader>
                      {tableHeader?.map((column, index) => (
                        <TableHeaderCell
                          isTimeLineView={isTimeLineView}
                          styled={{ width: column.width }}
                          key={index}
                        >
                          {column.value}
                        </TableHeaderCell>
                      ))}
                    </TableHeader>
                    <TableBody emptyRows={emptyRows}>
                      {rowsToShow.map((row, index) => (
                        <TableRow
                          isSelected={!!(isExpandAttr && isExpandAttr[index])}
                          style={{ display: "flex", flexWrap: "wrap" }}
                          key={index}
                        >
                          {renderTableRows(
                            row,
                            handleOpenScheduleModal,
                            handleOpenExamResult,
                            handleDownloadFile,
                            index,
                            undefined,
                            undefined,
                            headerDataTable,
                            isTimeLineView,
                            setIsExpandAttr,
                            isExpandAttr
                          )}
                        </TableRow>
                      ))}
                      <TableRow>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#red",
                            flex: 1,
                          }}
                        />
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <div style={{ display: "flex" }}>
                    <ContainerHeaderTableRows>
                      {tableHeader?.map((column, index) => (
                        <div>
                          <TableHeaderCell
                            isTimeLineView={isTimeLineView}
                            styled={{
                              width: column.width,
                              isRowRresentation,
                            }}
                            style={{ display: "flex", alignItems: "center" }}
                            key={index}
                          >
                            <Tooltip description={column.label as string}>
                              {column.value}
                            </Tooltip>
                          </TableHeaderCell>
                        </div>
                      ))}
                    </ContainerHeaderTableRows>
                    <TableBody
                      emptyRows={emptyRows}
                      isFull={isFull}
                      isRowRresentation={isRowRresentation}
                    >
                      {rowsToShow.map((row, index) => (
                        <TableRow
                          styled={{ isRowRresentation }}
                          style={{
                            backgroundColor:
                              index === 0
                                ? isTimeLineView
                                  ? "#558eff15"
                                  : "#558EFF35"
                                : "",
                          }}
                          rowIndex={index}
                          key={index}
                        >
                          {renderTableRows(
                            row,
                            handleOpenScheduleModal,
                            handleOpenExamResult,
                            handleDownloadFile,
                            index,
                            isRowRresentation,
                            ItemsPerPage,
                            undefined,
                            isTimeLineView
                          )}
                        </TableRow>
                      ))}

                      {emptyRows > 0 && (
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#red",
                            flex: 1,
                          }}
                        />
                      )}
                    </TableBody>
                  </div>
                )}
              </OverflowWrapper>

              <Pagination isTimeLineView={isTimeLineView}>
                <Button
                  disabled={(tableMetaPagination?.currentPage || 1) === 1}
                  onClick={() => getTableData(tableMetaPagination.prev)}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
                <PageNumber>
                  {tableMetaPagination?.currentPage || 1} de{" "}
                  {tableMetaPagination?.lastPage || 1}
                </PageNumber>
                <Button
                  disabled={
                    (tableMetaPagination?.currentPage || 1) ===
                    (tableMetaPagination.lastPage || 1)
                  }
                  onClick={() => getTableData(tableMetaPagination.next)}
                >
                  <i className="fas fa-chevron-right" />
                </Button>
              </Pagination>
            </>
          )}
        </TableWrapper>
      )}
    </>
  );
};

export default Table;
