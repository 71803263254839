import { useEffect, useState } from "react";
import { Icon } from "../icon";
import { IDataNotification, INotification } from "./interfaces";
import { DetailModal } from "./modalDetail";
import * as S from "./styled";

export const Notifications = ({ notifications }: INotification) => {
  const [toogleAnimation, setToogleAnimation] = useState(false);
  const [tooglePopup, setTooglePopup] = useState(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);

  const [notificationDetail, setNotificationDetail] =
    useState<IDataNotification | null>(null);

  const handleCloseModal = () => {
    setNotificationDetail(null);
    setDetailModal(false);
    return;
  };

  const handleOpenModal = (notification: IDataNotification) => {
    setNotificationDetail(notification);
    setDetailModal(true);
    return;
  };

  useEffect(() => {
    if (notifications) setToogleAnimation(true);
    if (!notifications || notifications?.length < 1) {
      setToogleAnimation(false);
      setTooglePopup(false);
    }
  }, [notifications]);

  return (
    <S.NotificationContainer>
      {toogleAnimation && (
        <S.NotificationCount>
          {notifications && notifications.length > 99
            ? "99+"
            : notifications?.length}
        </S.NotificationCount>
      )}
      <S.AnimationNotification toogleAnimation={toogleAnimation}>
        <Icon
          onMouseEnter={() => setTooglePopup(true)}
          onMouseOut={() => setTooglePopup(false)}
          icon="bell"
          cursor={
            notifications && notifications?.length > 0 ? "pointer" : "initial"
          }
          type="solid"
          customStyleds={{ fontSize: "20px" }}
        />
      </S.AnimationNotification>
      {tooglePopup && notifications && notifications.length > 0 && notifications &&  (
        <>
          <S.NotificationContent
            onMouseOver={() => setTooglePopup(true)}
            onMouseOut={() => setTooglePopup(false)}
          >
            {
              notifications.map((notify, index) => (
                <li key={index} onClick={() => handleOpenModal(notify)}>
                  <p>{notify.title}</p>
                  {/* <div>
                    <Icon
                      cursor="pointer"
                      handleClick={() =>
                        notify.remove && notify.remove({ id: notify.id })
                      }
                      customStyleds={{ fontSize: "15px", zIndex: 99999999 }}
                      icon="times"
                      type="solid"
                    />
                  </div> */}
                </li>
              ))}
          </S.NotificationContent>
        </>
      )}

      <DetailModal   
        isOpen={detailModal && Boolean(notificationDetail)}
        notification={notificationDetail}
        onClose={handleCloseModal}
      />
    </S.NotificationContainer>
  );
};
