import React from "react";

export const ModalStyled: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  outline: "none",
}

export const ListStyled: React.CSSProperties = {
  outline: "none",
  width: "100%", 
  maxWidth: 480, 
  maxHeight: 400,
  height: "100%",
  backgroundColor: "#ffffff",
  borderRadius: "10px", 
}

export const ListHeader: React.CSSProperties = {
  padding: "15px",
  borderRadius: "10px 10px 0px 0px",
  backgroundColor: "#558eff",
  color: "#ffffff",
  display: "flex",
  justifyContent: "space-between",
}