import styled from "styled-components";

const StyledCardsContainer = styled.div`
  width: 100%;
  min-width: 70%;

  display: flex;

  align-items: center;
  justify-content: center;
`;

const CardsContainer = styled.div`
  width: 100%;
  /* max-width: 1480px; */

  display: grid;
  grid-template-columns:  repeat(auto-fit, minmax(500px, 1fr));
  justify-items: center;
  align-items: center;
  gap: 30px;
  margin: 0px 35px;


  @media (max-width: 1300px) {  
    grid-template-columns:  repeat(auto-fit, minmax(400px, 1fr));
  };

  @media (max-width: 1150px) {  
    grid-template-columns:  repeat(auto-fit, minmax(300px, 1fr));
  };
`;

export { CardsContainer, StyledCardsContainer };

