import styled from "styled-components";

const StyledContainer = styled.div`
  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }
`;

export { StyledContainer };

