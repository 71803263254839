import styled from 'styled-components';

export const SelectHeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0px;
    border-bottom: solid 2px #dddddd;

    width: 100%;
`;
