import { FC } from "react";
import { ITooltipProps } from "./interface";
import { TooltipBox } from "./styled";

export const Tooltip: FC<ITooltipProps> = ({ customStyled, children, description, positionInitial }) => {
    return (
        <TooltipBox styled={{ positionInitial }} style={customStyled} role="tooltip" aria-label={description} >
            {children}
        </TooltipBox>
    );
}

