import { useAuth } from "../../providers/Auth";
import { useAppDispatch } from "../../store";
import { userLogoutThunk } from "../../store/slices/userAuthSlice/thunks/logoutThunk";
import { StyledContainer, StyledLogOutButton } from "./style";

export const BlockUser = () => {
  const Block = require("../../assets/images/hand-stop.png");

  const { handleLogOut } = useAuth();
  const dispatch = useAppDispatch();

  return (
    <StyledContainer>
      <div>
        <img src={Block} alt="Blocking hand" />
        <p>Não foi possível autenticar seu acesso.</p>
        <p>Caso o problema persista, contate o administrador de seu sistema</p>
        <StyledLogOutButton onClick={() => {
          handleLogOut();
          dispatch(userLogoutThunk());
        }}>Voltar</StyledLogOutButton>
      </div>
    </StyledContainer>
  );
};
