import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiAuth } from "../../../../../services/apiAuth";
import { IAuthResponse } from "../../interfaces";
import { ISessionData, IUserLoginThunkDTO } from "./interfaces";

export const userLoginThunk = createAsyncThunk('userLogin', async ({
    apiKey,
    authClient
}: IUserLoginThunkDTO, thunkAPI): Promise<IAuthResponse> => {

    try {

        if (authClient?.clientName) {
            if (authClient.clientName === 'sabara') {
                const { sabara } = authClient?.clientAuth;

                const { data } = await apiAuth.post(`/auth/apiKey`, {
                    extraQueryData: {
                        patient: sabara?.patient,
                        userId: sabara?.userid,
                        userName: sabara?.username,
                        companyCode: 'sabara'
                    },
                    typeQueryService: 'authWithPatient',
                    apiKey: sabara?.key,
                    applicationType: 'datamea'
                }) as { data: IAuthResponse };

                return data;
            }

            if (authClient.clientName === 'saocaetano') {
                const { saocaetano } = authClient?.clientAuth;

                let sessionData: ISessionData = {
                    client: authClient.clientName,
                    key: saocaetano?.key,
                    patient: saocaetano?.patient,
                    userid: saocaetano?.userid,
                    username: saocaetano?.username,
                };

                sessionStorage.setItem('@Auth:client', JSON.stringify(sessionData));

                const { data } = await apiAuth.post(`/auth/apiKey`, {
                    extraQueryData: {
                        patient: saocaetano?.patient,
                        userId: saocaetano?.userid,
                        userName: saocaetano?.username,
                        companyCode: 'saocaetano'
                    },
                    typeQueryService: 'authWithPatient',
                    apiKey: saocaetano?.key,
                    applicationType: 'datamea'
                }) as { data: IAuthResponse };

                return data;
            }
        };

        if (apiKey) {

            const { data } = await apiAuth.get(`/auth/dinamicToken/`, {
                params: {
                    token: apiKey,
                    applicationType: 'datamea'
                }
            }) as { data: IAuthResponse };

            return data;
        };

        console.error('Para realizar que o usuário seja conectado à API deverá ser informado a API KEY ou os dados de acesso do mesmo (Email e Senha).');
        return { type: 'unknown-error', statusCode: 500, message: 'Erro desconhecido! Entre em contato com o administrador do site.' };

    } catch (error) {
        console.error(error);
        return { type: 'unknown-error', statusCode: 500, message: error as string }
    };

}); 