import styled from "styled-components";
import { IHeaderPopupStyledProps } from "./interfaces";

export const Container = styled.header<IHeaderPopupStyledProps>`
    width: 100%;
    height: ${({ hg = 5 }) => `${hg}rem`};
    
    background-color: ${({ bgColor = '#558eff'}) => bgColor};

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px;

    border-radius: inherit;

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
`;