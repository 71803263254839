import "./styles.css";
import { ContainerInterface } from "./interface";

export const ContainerComponent = (props: ContainerInterface) => {
    return(
        <>
            <div className="container-component">
                {props.children}
            </div>
        </>
    );
}