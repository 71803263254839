import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  height: 90px;
  background-color: var(--color-white);
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);

  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  margin-bottom: 10px;
  
  .infosDiv {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 5px;
    padding-bottom: 5px;

    p {
      color: var(--color-neutralBlack);
      font-weight: bolder;
      font-size: .9rem;
      margin-bottom: 10px;
    }
    span {
      font-size: 1.2rem;
      color: var(--color-secondary);

    }
  }

  .iconDiv {
    i {
      font-size: 2rem;
      color: var(--color-secondary);
    }
  }
  

`;

export { StyledContainer };

