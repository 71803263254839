import styled from 'styled-components';

export const BoxContainerSearch = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`;

export const Search = styled.input`
    outline: none;
    border: none;
    background-color: #ffffff;
    border: solid 2px #c3c3c380;
    border-radius: 5px;
    flex: 1;
    padding: 10px 15px;
`;

export const Button = styled.button`
    padding: 10px 0px;

    background-color: #3d79f2;
    outline: none;
    border: none;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px 15px;

    cursor: pointer;
    color: white;

    transition: all 0.3s;

    &:hover {
        background-color: #6a8fd9;
    }
`;
