import { createContext, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api, shouldGoBackToLogin } from "../../services/api";
import {
  applyDataPatient,
  openPopup,
  toogleLoading,
} from "../../store/slices/userAuthSlice";
import { IAuthResponse, IPatientDataProps } from "../../store/slices/userAuthSlice/interfaces";
import storage from "../../utils/storage";
import {
  AuthProviderData,
  AuthProviderProps,
  SearchedUsers
} from "./types";

/**
 * Auth context
 */
export const AuthContext = createContext<AuthProviderData>(
  {} as AuthProviderData
);

/**
 *
 * @param children (TSX elements)
 * @returns AuthProvider, funções e tsx
 */
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState<SearchedUsers[]>([]);
  const [isDoctor, setIsDoctor] = useState(false);
  const [patientToShow, setPatientToShow] = useState({} as IPatientDataProps);
  const [shouldBlockUser, setShouldBlockUser] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  /**
   * Função para buscar usuarios, caso voce seja um medico.
   * @param name nome do usuari
   */
  async function handleSearchUser(name: string) {
    try {
      setLoadingSearch(true);
      const { data } = await api.get(`/patient/${name}`);

      if (data.length > 0) {
        setSearchedUsers(data);
      } else {
        setSearchedUsers([]);
      }

      setLoadingSearch(false);
    } catch (error) {
      setSearchedUsers([]);
    }
  }

  /**
   * Função para pegar dados do usuario procurado.
   * @param token string
   */
  async function handleGetUserDetails(token: string) {
    try {
      dispatch(toogleLoading());

      const { data } = (await api.get(`/patient/token/${token}`)) as {
        data: IAuthResponse;
      };

      console.log("data", data)

      if (data.type === 'consentment-denied') {
        dispatch(
          openPopup({
            title: 'Paciente sem Consentimento!',
            message: data.message
        }))  
        navigate("/");
      } else {
        dispatch(applyDataPatient(data.value));
      };
    } catch (error) {
      console.error({ error });
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        localStorage.clear();
        navigate("/auth/login");
      }
    }
  }

  /**
   * Função para fazer logout e limpar variaveis
   */
  function handleLogOut() {
    storage.logOut();
    setSearchedUsers([]);
    setIsDoctor(false);
    setPatientToShow({} as IPatientDataProps);
    setShouldBlockUser(false);
    navigate("/auth/login");
  }

  return (
    <AuthContext.Provider
      value={{
        isDoctor,
        patientToShow,
        shouldBlockUser,
        loadingAuth,
        searchedUsers,
        setIsDoctor,
        setSearchedUsers,
        handleLogOut,
        setLoadingAuth,
        handleGetUserDetails,
        handleSearchUser,
        loadingSearch,
        setShouldBlockUser,
        setPatientToShow,
        setLoadingSearch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
