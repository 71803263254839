import styled from 'styled-components';

export const SelectContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    height: 350px;
`;
