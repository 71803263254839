import { InfosCardSkeleton } from "../../../../components/Skeletons/InfosCardSkeleton";

export function InfoCardSkeletonContainer() {
  return (
    <>
      <InfosCardSkeleton />
      <InfosCardSkeleton />
      <InfosCardSkeleton />
      <InfosCardSkeleton />
    </>
  );
}
