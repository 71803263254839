import styled from "styled-components";
import { ITimeLineContentStyledProps } from "./interfaces";

export const Content = styled.div<ITimeLineContentStyledProps>`
    transition: all .3s;

    z-index: 999;
    max-width: 18rem;
    position: fixed;

    margin-left: -10px;

    justify-content: flex-end;

    background-color: #3a3a3a;
    border-radius: 10px;

    color: #ffffff;
    padding: 15px;

    display: ${({ toogleToltipContent }) => toogleToltipContent ? 'flex' : 'none'};
    align-items: flex-end;
    justify-content: flex-end;
`;