import { CejamConfig } from "./CejamConfig";
import { DefaultConfig } from "./DefaultConfig";
import { SabaraConfig } from "./SabaraConfig";
import { SaoCaetanoConfig } from "./SaoCaetanoConfig";

export const API_URL = process.env.API_URL as string;

export const ClientConfig = {
    'default': DefaultConfig,
    'sabara': SabaraConfig,
    'saocaetano-stg': SaoCaetanoConfig,
    'saocaetano': SaoCaetanoConfig,
    'cejam': CejamConfig,
    'cejam-poc': CejamConfig,
}