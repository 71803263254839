import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;

  /* min-height: 50vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTimeLineContainer = styled.div`
  width: 93%;
  height: 100%;

  position: relative;

  /* background-color: var(--color-white);
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px; */

  .vertical-timeline-item-main-infos {
    margin-bottom: 15px;
    h4 {
      color: var(--color-timeline-atribute);
      margin: none;
    }

    p {
      font-size: 1rem;
      margin-top: 0px !important;
      color: var(--color-timeline-atribute-content);
    }
  }

  .vertical-timeline-item-container {
    margin-bottom: 15px;
    h4 {
      color: var(--color-timeline-atribute);
      margin: none;
    }

    .vertical-timeline-item-type2-diagnostic-containe {
      display: flex;
      flex-direction: column;
      width: 70%;

      margin-top: 10px;
      padding: 10px;

      background-color: #e9f7fe;
    }
  }

  .vertical-timeline-element-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.5rem;
    }
  }
`;

export { StyledContainer, StyledTimeLineContainer };

