import { SearchedUsers } from "../../../../../../providers/Auth/types";
import { StyledUserCardContainer, StyledUserCardDataContainer, StyledUserCardHeader } from "./style";

interface ListUserCardInterface {
  user: SearchedUsers;
  onClick: (token: string) => void;
}

export default function ListUsersCard({ user, onClick }: ListUserCardInterface) {
  const defaultImage = require("../../../../../../assets/images/generic_avatar.png");

  const getSex = () => {
    if (!user.sex) return 'Não Informado';

    switch (user.sex.toLowerCase().charAt(0)) {
      case 'm':
        return 'Masculino';
      case 'f':
        return 'Feminino';
      default:
        return 'Outro';
    }
  };

  return (
    <StyledUserCardContainer onClick={() => {
      onClick(user.publicToken)
    }} className="listUser-container">
      <StyledUserCardHeader className="listUser-header">
        <img src={defaultImage} alt="Foto de avatar generico dataMea" />
        <h1>{user.name}</h1>
      </StyledUserCardHeader>

      <StyledUserCardDataContainer className="listUser-data">
        <ul className="listUser-list">
          <li className="listUser-item">
            {user.cpf && (
              <div>
                <p>CPF:</p>
                <span>{user.cpf}</span>
              </div>
            )}
          </li>
          {user.mother && (
            <li className="listUser-item">
              <div>
                <p>Nome da mãe:</p>
                <span>{user.mother}</span>
              </div>
            </li>
          )}
          {user.sex && (
            <li className="listUser-item">
              <div>
                <p>Sexo:</p>
                <span>{getSex()}</span>
              </div>
            </li>
          )}
          {user.birth && (
            <li className="listUser-item">
              <div>
                <p>Nascimento:</p>
                <span>{user.birth}</span>
              </div>
            </li>
          )}
        </ul>
      </StyledUserCardDataContainer>
    </StyledUserCardContainer>
  );
}
