import styled from 'styled-components';
import { IImageStyledsProps } from './interfaces';

export const Container = styled.div<IImageStyledsProps>`
    width: ${({ size }) => (size ? `${size}rem` : '6rem')};
    height: ${({ size }) => (size ? `${size}rem` : '6rem')};

    img {
        width: 100%;
        height: 100%;
    }
`;
