import { Skeleton } from "@mui/material";
import { StyledSkeletonContainer } from "./style";

export function TableSkeletons() {
  return (
    <>
      <StyledSkeletonContainer>
        <Skeleton
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "1.3rem",
          }}
          width={"100%"}
          variant="rectangular"
          height={'100%'}
        >
          Carregando...
        </Skeleton>
      </StyledSkeletonContainer>
    </>
  );
}
