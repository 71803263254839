import { configInterface } from "../../Types/Config";
import { ClientConfig } from "../../config";

export const useClientConfig = (): configInterface => {
    const [ host ] = window.location.host.toLowerCase().split('.');
    
    // @ts-ignore
    const clientConfig = ClientConfig[host];
    
    if (!clientConfig) {
        return ClientConfig.default;
    } else return clientConfig;
};