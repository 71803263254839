import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  max-width: 500px;

  height: 40px;

  background-color: var(--color-white);
  border-radius: 100px;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;

  cursor: text;

  svg {
    cursor: text !important;
    color: var(--color-grayLight);

    width: 16px !important;
    height: 16px !important;

    margin-left: 10px !important;
    margin-right: 0px !important;
  }

  form {
    width: 100%;
  }

  input {
    margin-left: 10px;

    border: none;
    outline: none;

    width: 80%;

    color: var(--color-grayDark);
  }
`;

export { StyledContainer };

