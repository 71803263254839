import { BlockUser } from "../../components/Blockuser";
import Summary from "../Summary";
import TimeLine from "../TimeLine";

/**
 * Função que retorna a pagina atual.
 * @param page Paginas disponiveis da aplicação: Sumario | Linha do tempo
 * @returns TSX element
 */
export function handleGetPage(page: "Sumario" | "Linha do tempo") {
  const pages = {
    Sumario: <Summary />,
    "Linha do tempo": <TimeLine />,
  };

  if (!pages[page]) {
    return <BlockUser />;
  }

  return pages[page];
}
