import { IconsTypes } from "@dataopera/dto-ui/dist/components/Icon/iconTypes";
import { Modal } from "@mui/material";
import { useState } from "react";
import { Tooltip } from "../../../../../Tooltip";
import { Icon } from "../../../../../icon";
import { Popup } from "../../../../../popup";
import {
  ContainerTitle,
  HeaderContainer,
  TitleContainer,
} from "../../../style";
import { IDescriptionCellProps } from "./interfaces";
import * as S from "./styled";

export const DescriptionCell = ({
  row,
  headerDataTable,
}: IDescriptionCellProps) => {
  const [openPopup, setOpenPopup] = useState(false);

  const tooglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const description = row.find((item) => {
    if (item.value.length > 100) return item  
  });

  const date = row.find((item) => {
    if (item.value.length === 16) return item;  
  });
  
  const newTextDescriptionTwoLines = description?.value.split("\n", 2).map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
  
  const newTextDescriptionFull = description?.value.split("\n").map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });

  return (
    <>
      <S.DescriptionCellWrapper>
        <S.Description>{newTextDescriptionTwoLines}</S.Description>
          <S.LerMais onClick={tooglePopup} >
            Continuar Lendo...
          </S.LerMais>
        </S.DescriptionCellWrapper>

      {openPopup && (        
        <Modal onClose={tooglePopup} open={openPopup}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Popup.Root
              align="center"
              bgOpacity={100}
              style={{
                width: "100%",
                height: "100%",
                minWidth: "300px",
                maxWidth: "650px",
                minHeight: "400px",
                maxHeight: "600px",
              }}
            >
              <Popup.Header hg={3}>
                <HeaderContainer>
                  <div className="container-wrapper">
                    <ContainerTitle>
                      <Icon
                        icon={headerDataTable?.iconType as IconsTypes}
                        customStyleds={{
                          fontSize: "1.25rem",
                        }}
                        type="solid"
                      />
                      <TitleContainer>
                        <h1 style={{ fontSize: '.9rem' }} >{headerDataTable?.title}</h1>
                        <Tooltip description={headerDataTable?.subtitle as string}>
                          <Icon
                            cursor="pointer"
                            type="light"
                            icon={"info-circle"}
                          />
                        </Tooltip>
                      </TitleContainer>
                    </ContainerTitle>
                    <Tooltip
                      positionInitial={"row-reverse"}
                      description="Fechar"
                    >
                      <Icon
                        cursor="pointer"
                        handleClick={tooglePopup}
                        type="solid"
                        icon={"close"}
                      />
                    </Tooltip>
                  </div>
                </HeaderContainer>
              </Popup.Header>
              <Popup.Content
                style={{
                  borderRadius: "0px 0px 8px 8px",
                  textAlign: "start",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignContent: "flex-start",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  fontSize: ".9rem",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  flexDirection: "column",
                  gap: "1rem",
                  lineHeight: '1rem'
                }}
              >
                <h3 style={{ fontSize: '.95rem', borderBottom: '1px solid', }} >{date?.value}</h3>
                <p>{newTextDescriptionFull}</p>
              </Popup.Content>
            </Popup.Root>
          </div>
        </Modal>
      )}
    </>
  );
};
