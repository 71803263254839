import styled from "styled-components";

const GlobalIAContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DataIAContainer = styled.div`
    width: 100%;
    /* max-width: 1300px; */
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 20px;

    h4, h3 {
      font-size: 1.2rem;
    }
    
    li, p {
      font-size: 1rem;
    }
`;

const MenuTabsPatientInformationsArea = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuTabsPatientInformationsContent = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLayout = styled.div`
  height: 100vh;

  background-color: var(--color-background);

  margin-top: 30px;

  display: flex;
  flex-direction: column;

  .align-center {
    align-self: center;

    margin-top: 25px;
  }

  @media screen and (max-width: 1700px) {
    zoom: 90%;
  }
  @media screen and (max-width: 1600px) {
    zoom: 85%;
  }
  @media screen and (max-width: 1350px) {
    zoom: 80%;
  }

  @media screen and (max-width: 1150px) {
    zoom: 100%;
  }
`;


const StyledButton = styled.button<{ selected: boolean }>`
  border: none;
  background-color: transparent;
  color: ${({ selected }) => (selected ? "#558EFF" : "#52575C")};

  width: 150px;

  cursor: pointer;

  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1px;

  :hover {
    color: var(--color-secondary);
  }
`;

export { DataIAContainer, GlobalIAContainer, MenuTabsPatientInformationsArea, MenuTabsPatientInformationsContent, StyledButton, StyledLayout };

