/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DataHAL } from "@dataopera/dto-ui";
import { useEffect, useState } from "react";
import { InfosCard } from "../../components/InfosCard";
import { useClientConfig } from "../../hooks/useClientConfig";
import { useRequests } from "../../providers/Requests";
import { useAppSelector } from "../../store";
import { DataIAContainer, GlobalIAContainer } from "../Dashboard/styles";
import { InfoCardSkeletonContainer } from "./components/InfosCardSkeleton";
import { TableContainer } from "./components/TableContainer";
import { CardsContainer, StyledCardsContainer } from "./style";

export interface InfosInterface {
  title: string;
  value: number;
  color: string;
  type: "emergency" | "others" | "exam" | "admission";
}

const Summary = () => {
  const { handleGetHeaderSlots, headerInfos, getTableData } = useRequests();
  const { shouldBlockUser, patientToShow } = useAppSelector(
    (state) => state.user
  );

  const [isLoading, setIsLoading] = useState(false);

  const { contentSlots, isActiveDataHAL } = useClientConfig();

  useEffect(() => {
    const hasToken = JSON.stringify(sessionStorage.getItem("@data-mea:token"));

    if (!shouldBlockUser && hasToken.length > 0) {
      setIsLoading(true);
      handleGetHeaderSlots(patientToShow.mpiKey);
      getTableData(contentSlots, patientToShow);
      setIsLoading(false);
    }

    return () => {
      setIsLoading(false);
    };
  }, [patientToShow]);

  let publicToken = "";
  console.log("patientToShow", patientToShow);

  publicToken = patientToShow.mpiKey;

  const [isViewDataIA, setIsViewDataIA] = useState(
    process.env.REACT_APP_PERMISSION_DATAIA === "TRUE"
  );

  useEffect(() => {
    if (isActiveDataHAL) setIsViewDataIA(isActiveDataHAL);
  }, []);

  const SUMMARY_BLOCK_ONE_API_URL = `${process.env.REACT_APP_IA_API_URL}/summary_ws/${publicToken}`;
  const SUMMARY_BLOCK_TWO_API_URL = `${process.env.REACT_APP_IA_API_URL}/summary_ws2/${publicToken}`;
  const CHAT_BOT_API_URL = `${process.env.REACT_APP_IA_API_URL}/ws/${publicToken}`;

  return (
    <>
      {headerInfos.length > 0 && (
        <StyledCardsContainer>
          <CardsContainer>
            {isLoading ? (
              <InfoCardSkeletonContainer />
            ) : (
              <>
                {headerInfos?.map(
                  (elm, key) =>
                    elm && (
                      <InfosCard
                        key={key}
                        inconType={elm.type}
                        title={elm.title}
                        value={elm.value}
                      />
                    )
                )}
              </>
            )}
          </CardsContainer>
        </StyledCardsContainer>
      )}
      {isViewDataIA && (
        <GlobalIAContainer>
          <DataIAContainer>
            <DataHAL.SummaryIA
              urls={{
                blockOneURL: SUMMARY_BLOCK_ONE_API_URL,
                blockTwoURL: SUMMARY_BLOCK_TWO_API_URL,
              }}
              theme={{
                titleColor: "#558eff",
                textBox: {
                  backgroundColor: "#edf2ff",
                },
              }}
            />
            <DataHAL.PatientRecord
              patientRecordURL={CHAT_BOT_API_URL}
              theme={{
                titleColor: "#558eff",
                textBox: {
                  bot: {
                    backgroundColor: "#bdffc0",
                  },
                },
              }}
            />
          </DataIAContainer>
        </GlobalIAContainer>
      )}
      <TableContainer isLoading={isLoading} />
    </>
  );
};

export default Summary;
