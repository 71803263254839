import { IImageProps } from './interfaces';
import * as S from './styled';

export const Image = ({ url, alt, size, ...rest }: IImageProps) => {
    return (
        <S.Container size={size} {...rest}>
            <img src={url} alt={alt} />
        </S.Container>
    );
};
