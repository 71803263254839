import { Icon } from "../../icon";
import { IconsTypes } from "../../icon/data";
import { Tooltip } from "../../Tooltip";
import { ContainerTitle, HeaderContainer, TitleContainer } from "../NewTable/style";
import { ModalContainer } from "./style";

type ModalWrapperType = {
  title: string;
  closeModal: () => void;
  children: React.ReactNode;
  iconType: IconsTypes;
};

function ModalWrapper({ title, iconType, children, closeModal }: ModalWrapperType) {
  return (
    <>
      <ModalContainer>
        <HeaderContainer>
          <div className="container-wrapper">
            <ContainerTitle>
              <Icon icon={iconType} type="solid" size="1.5x" />
              <TitleContainer>
                <h1>{title}</h1>
                <Tooltip description={title} >
                  <Icon cursor="pointer" icon='info-circle' type="regular" />
                </Tooltip>
              </TitleContainer>
            </ContainerTitle>
            <Tooltip positionInitial={'row-reverse'} description="Fechar" >
              <Icon cursor="pointer" handleClick={closeModal} icon='times' type="solid" />
            </Tooltip>
          </div>
        </HeaderContainer>
        <div>{children}</div>
      </ModalContainer>
    </>
  );
}

export default ModalWrapper;
