import styled from "styled-components";

export const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 50px;
    height: 50px;

    z-index: 9;
`;

export const NotificationCount = styled.div`
    margin-left: 25px;
    font-weight: bolder;
    color: #558eff;
    font-size: .8rem;

    position: absolute;
    top: -12px;

    border-radius: 50%;
    z-index: 9;
`;

export const AnimationNotification = styled.div<{ toogleAnimation: boolean }>`


    @keyframes AnimationNotification {
        0% {
            margin-left: -3px;
            rotate: 9deg;
        }
        50% {
            margin-left: 3px;
            rotate: -9deg;
        }
        100% {
            margin-left: -3px;
            rotate: 9deg;
        }
    };

    animation: ${({ toogleAnimation = true }) => {
        if (toogleAnimation) return '.5s infinite AnimationNotification';
        else return '';
    }};


    * {
        color: ${({ toogleAnimation = true }) => {
        if (toogleAnimation) return '#558eff';
        else return '#151515';
    }};

        
        padding: 0px 0px 20px 10px;
    }

`;

export const NotificationContent = styled.ul`
    position: absolute;

    top: -22px;
    margin-left: -350px;

    width: 370px;

    position: relative;

    background-color: #e4edff;

    z-index: 999999;

    box-shadow: 1.5px 1.5px 3px #c3c3c390;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    border-radius: 10px;
    padding: 10px;

    min-height: 160px;
    max-height: 130px;

    overflow-Y: scroll;
    overflow-X: hidden;

    ::-webkit-scrollbar              { 
         display: none;
    };
    ::-webkit-scrollbar-track        { 
         display: none;
     };

    li {
    
        width: 100%;

        background-color: #e4edff;
        padding: 10px 15px;
        border-radius: 5px;
        
        display: flex;
        justify-content: space-between;

        align-items: center;
        cursor: pointer;
  
        text-align: center;

        color: #558eff;

        box-shadow: none;

        transition: all .5s;

        position: relative;

        p {
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        div {
            right: 10px;
            
            width: 17px;
            height: 17px;

            transition: all .5s ease;

            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            opacity: 0;

            transition: all .3s;
            
            * {
                color: #558eff;
            }

            &:hover {
                
            * {
                color: #e4edff;
            }
                background-color: #558eff;
                border-radius: 3px;
            }
        }

        border: solid 2px transparent;
        border-left: solid 2px #558eff90;
        border-bottom: solid 2px #558eff90;

        &:hover {
            
            background-color: 0;
            color: #558eff;

            box-shadow: .1px .1px 3px .1px #558eff70;

            text-shadow: 0;
            
        border: solid 2px #558eff70;

            div {
                opacity: 1;
            }
        }
    }
`;