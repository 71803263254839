import styled from "styled-components";
import { IRootPopupStyledProps } from "./interfaces";

export const Container = styled.div<IRootPopupStyledProps>`
    position: fixed;
    width: 100vw;
    height: 100vh;

    display: flex;
    
    align-items: ${({ align = 'center'}) => {
        if (align === 'top-left' || align === 'top-center' || align === 'top-right') return 'top';
        if (align === 'bottom-left' || align === 'bottom-center' || align === 'bottom-right') return 'flex-end';
        return 'center'
    }};

    justify-content: ${({ align = 'center' }) => {
        if (align === 'top-left' || align === 'bottom-left') return 'flex-start';
        if (align === 'top-right' || align === 'bottom-right') return 'flex-end';
        if (align === 'top-center' || align === 'bottom-center' || align === 'center') return 'center';
    }};

    padding: 20px;

    background-color: ${({ bgOpacity, isBgColor = true}) => {
        if (isBgColor) {
            return bgOpacity ? `rgb(0 0 0 / ${bgOpacity}%` : 'rgb(0 0 0 / 65%)';
        }
        else return 'none';
    }};

    z-index: 999999;
`;

export const Content = styled.div<IRootPopupStyledProps>`
    width: ${({ wt = 45 }) => `${wt}rem`};
    height: ${({ hg = 25 }) => `${hg}rem`};

    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-color: ${({ bgColor = '#ffffff' }) => bgColor};

    border-radius: ${({ bdRadius = 1 }) => `${bdRadius}rem`};
`;