import styled from "styled-components";

export function SearchPatientMessage() {
  const lookingIcon = require("../../../../assets/images/lupa.png");
  return (
    <StyledContainer>
      <img src={lookingIcon} alt="Looking icon" />
      <p>Utilize o campo acima para pesquisar por um paciente.</p>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  height: 100vh;

  flex-direction: row;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  p {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: var(--color-grayDark);
  }
`;
