import styled from "styled-components";
import { IFooterPopupStyledProps } from "./interfaces";

export const Container = styled.footer<IFooterPopupStyledProps>`
    width: 100%;

    height: ${({ hg = 5 }) => `${hg}rem`};
    
    background-color: ${({ bgColor = '#558eff'}) => bgColor};

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};

    border-radius: inherit;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    gap: 25px;

    padding: 20px;

    button {
        outline: none;
        border: solid 2px #c23b22;

        color: #ffffff;
        font-weight: bold;
        padding: 10px 20px;

        cursor: pointer;

        border-radius: 10px;

        background-color: #c23b22;

        transition: all .3s;

        &:hover {        
            background-color: #c23b2250;
            color: #ffffff;

        };
        
    };
`;