import React from "react";
import { IconProps } from "./interface";
import { IconContainer } from "./styled";

export const Icon: React.FC<IconProps> = ({ 
    customStyleds, 
    type, 
    icon, 
    handleClick, 
    size, 
    cursor, 
    color,
    onMouseEnter,
    onMouseOut
}) => {
    return (
        <IconContainer 
            style={customStyleds}
            onClick={handleClick}
            onMouseOut={onMouseOut}  
            onMouseEnter={onMouseEnter}  
            styled={{ cursor, size, color }} 
            className={`fas fa-${type} fa-${icon}`} 
        />
    );
};
