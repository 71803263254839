import { configInterface } from "../Types/Config";
import logo from '../assets/images/Cejam/logo.png';

export const CejamConfig: configInterface = {
  internalName: 'cejam',
  displayName: 'CEJAM',
  logo: {
    size: '140px',
    url: logo
  },
  mevoDigitalRecipe: false,
  examSchedule: true,
  sortTablesWithoutData: "Bottom",
  isActiveDataHAL: false,
  localization: [
    {
      key: "prontuario",
      value: "Número do Munícipe"
    }
  ],
  headerSlots: [
    {
      componentType: "metric-list",
      datasource: "http://api.datamea.com.br/api/summary/encounter/total-numbers",
      position: 1,
    },
  ],
  contentSlots: [
    {
      iconType: 'hospital-user',
      dataType: "LastEncounters",
      title: "Últimos atendimentos",
      subtitle: "Atendimentos em ordem do mais recente para o mais antigo",
      componentType: "table",
      isFull: true,
      position: 1,
      datasource: "summary/encounter/last",
    },
    {
      iconType: 'calendar-alt',
      dataType: "future-scheduling",
      title: "Agendamentos futuros",
      subtitle: 'Informações sobre os futuros agendamentos.',
      componentType: "table",
      position: 2,
      datasource: "summary/schedule",
    },
    {
      iconType: 'person-dots-from-line',
      dataType: "Allergies",
      title: "Alergias e reações adversas",
      subtitle: 'Alergias e reações adversas',
      componentType: "table",
      position: 3,
      datasource: "summary/allergy",
    },
    {
      iconType: 'shield-virus',
      dataType: "immunization",
      title: "Imunizações",
      componentType: "table",
      subtitle: 'Informações sobre as vacinações de imunização do paciente.',
      position: 4,
      datasource: "summary/immunization",
    },
    {
      iconType: 'family',
      dataType: "history-family-illnesses",
      title: "Histórico de Doenças Familiares",
      subtitle: 'Histórico de doenças familiares do paciente.',
      componentType: "table",
      tableCode: "filterTableCode/Histórico Familiar",
      position: 5,
      datasource: "summary/history-family-illnesses",
    },
    {
      iconType: 'rectangle-history-circle-user',
      dataType: "personal-history-data",
      title: "Dados do Histórico Pessoal",
      subtitle: 'Dados do histórico pessoal do paciente.',
      componentType: "table",
      tableCode: "filterTableCode/Histórico Pessoal",
      position: 6,
      datasource: "summary/personal-history-data",
    },
    {
      iconType: 'user-doctor-message',
      dataType: "objectives-recommendations",
      title: "Objetivos e Recomendações",
      subtitle: 'Dados dos objetivos e recomendações realizadas para o pacitente.',
      componentType: "table",
      tableCode: "filterTableCode/Objetivos e Recomendações",
      position: 7,
      datasource: "summary/objectives-recommendations",
    },
    {
      iconType: 'user-doctor-message',
      dataType: "Conditions",
      title: "Condições e diagnósticos",
      subtitle: "Condições e diagnósticos",
      componentType: "table",
      position: 8,
      datasource: "summary/condition",
    },
    {
      iconType: 'heart-circle-exclamation',
      dataType: "comorbidities",
      title: "Dados Comorbidades",
      subtitle: 'Dados sobre as comorbidades do paciente.',
      componentType: "table",
      position: 9,
      datasource: "summary/comorbities",
    },
    {
      iconType: 'user-md',
      dataType: "impressao-clinica-hipotese",
      title: "Impressão Clinica / Hipótese",
      subtitle: 'Dados dos Impressão Clinica / Hipótese realizadas para o pacitente.',
      componentType: "table",
      tableCode: "filterTableCode/Impressão clínica",
      position: 10,
      datasource: "summary/clinicalimpression",
    },
    {
      iconType: 'face-thermometer',
      dataType: "current-disease",
      title: "Doença Atual do Paciente",
      subtitle: 'Dados sobre a doença atual do paciente.',
      componentType: "table",
      position: 11,
      datasource: "summary/current-disease",
      tableCode: "filterTableCode/História Doença Atual",
    },
    {
      iconType: 'person-rays',
      dataType: "Anthropometry",
      title: "Antropometria",
      subtitle: "Antropometria",
      componentType: "table",
      isFull: true,
      isRowRresentation: true,
      position: 12,
      datasource: "summary/anthropometry",
    },
    {
      iconType: 'hospital-user',
      dataType: "VitalSignals",
      title: "Sinais Vitais",
      subtitle: "Sinais Vitais",
      componentType: "table",
      isFull: true,
      isRowRresentation: true,
      position: 13,
      datasource: "summary/vital-signal",
    },
    {
      iconType: 'scalpel-line-dashed',
      dataType: "surgeries",
      title: "Cirurgias",
      subtitle: 'Dados das últimas cirurgias realizadas pelo paciente.',
      componentType: "table",
      position: 14,
      datasource: "summary/procedure",
    },
    {
      iconType: 'prescription-bottle-pill',
      dataType: "prescription-drugs",
      title: "Prescrição de Medicamentos",
      subtitle: 'Medicamentos prescritos para o paciente.',
      componentType: "table",
      position: 15,
      datasource: "summary/medication",
    },
    {
      iconType: 'file-medical',
      dataType: "ServiceRequest",
      title: "Exames Solicitados",
      componentType: "table",
      position: 16,
      datasource: "summary/servicerequest",
    },
    {
      iconType: 'file-lines',
      dataType: "observation",
      title: "Exames Realizados",
      componentType: "table",
      position: 17,
      datasource: "summary/diagnosticreport",
    },
    {
      iconType: 'file-contract',
      dataType: "scanned-documents",
      title: "Documentos Digitalizados",
      subtitle: 'Apresentação dos Documentos Digitalizados.',
      componentType: "table",
      position: 18,
      datasource: "summary/documentreference",
      params: "type.coding.id=55188-7",
    },
    {
      iconType: 'note-medical',
      dataType: "medical-prescriptions",
      title: "Receitas",
      subtitle: 'Dados sobre as receitas médicas do paciente.',
      componentType: "table",
      position: 19,
      datasource: "summary/documentreference",
      params: "type.coding.id=57833-6",
    },
    {
      iconType: 'heart-circle-exclamation',
      dataType: "opme",
      title: "OPME",
      subtitle: 'Dados de OPME.',
      componentType: "table",
      isFull: false,
      position: 20,
      datasource: "summary/opme",
    },
    {
      iconType: 'utensils-alt',
      dataType: "plano-alimentar-dietas",
      title: "Plano Alimentar Dietas",
      subtitle: 'Dados dos plano alimentar dietas realizadas para o pacitente.',
      componentType: "table",
      position: 21,
      datasource: "summary/nutrition-plan",
    },
    {
      iconType: 'person-circle-exclamation',
      dataType: "customer-service-complaint",
      title: "Queixa do Atendimento",
      subtitle: 'Queixas que o paciente fez em relação ao atendimento prestado.',
      componentType: "table",
      position: 22,
      datasource: "summary/customer-service-complaint",
    },
    // {
    //   iconType: 'prescription-bottle-pill',
    //   dataType: "medicines-informed-use",
    //   title: "Medicamentos com Uso Informado",
    //   subtitle: 'Informação sobre os medicamentos com uso informado.',
    //   componentType: "table",
    //   position: 13,
    //   datasource: "summary/medication",
    // },
    // {
    //   iconType: 'pills',
    //   dataType: "Medications",
    //   title: "Medicamentos em uso",
    //   subtitle: "Medicamentos que o paciente está utilizando.",
    //   componentType: "table",
    //   position: 12,
    //   datasource: "summary/medication",
    // },
    // {
    //   iconType: 'user-injured',
    //   dataType: "patient-accident",
    //   title: "Acidentes",
    //   subtitle: 'Histórico de pacientes ocorridos com pacitente.',
    //   componentType: "table",
    //   position: 7,
    //   datasource: "summary/accident",
    // },    
    // {
    //   iconType: 'person-to-door',
    //   dataType: "types-high",
    //   title: "Tipos de Alta",
    //   subtitle: 'Altas que o paciente teve por tipo.',
    //   componentType: "table",
    //   position: 24,
    //   datasource: "summary/discharge",
    // },
    // {
    //   iconType: 'hand-holding-medical',
    //   dataType: "EncountersTotalNumbers",
    //   title: "Quantidades por tipo de atendimento",
    //   subtitle: 'Apresentação da quantidade de exames por tipo de atendimento.',
    //   componentType: "chart",
    //   chartType: "pizza",
    //   position: 1,
    //   datasource: "summary/encounter/total-numbers",
    // },
    // {
    //   iconType: 'person-circle-exclamation',
    //   dataType: "customer-service-complaint",
    //   title: "Queixa do Atendimento",
    //   subtitle: 'Queixas que o paciente fez em relação ao atendimento prestado.',
    //   componentType: "table",
    //   position: 4,
    //   datasource: "summary/customer-service-complaint",
    // },
    // {
    //   iconType: 'user-md',
    //   dataType: "use-prostheses-pacemakers",
    //   title: "Uso de Proteses e Marcapassos",
    //   subtitle: 'Dados sobre as proteses e macapassos do paciente.',
    //   componentType: "table",
    //   position: 9,
    //   datasource: "summary/use-prostheses-pacemakers",
    // },
    // {
    //   iconType: 'notes-medical',
    //   dataType: "clinical-outcome",
    //   title: "Desfecho Clínico",
    //   subtitle: 'Informações o desfecho clínico do paciente.',
    //   componentType: "table",
    //   position: 25,
    //   datasource: "summary/discharge",
    // },

    // {
    //   iconType: 'utensils-alt',
    //   dataType: "plano-alimentar-dietas",
    //   title: "Plano Alimentar Dietas",
    //   subtitle: 'Dados dos plano alimentar dietas realizadas para o pacitente.',
    //   componentType: "table",
    //   position: 16,
    //   datasource: "summary/nutrition-plan",
    // },
  ],
};