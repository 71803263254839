export const capitalizeText = (text: string): string => {
  if (!text || typeof text  !== "string") return text;

  return text
    .trim()
    .toLowerCase()
    .split(/([.!?] *)/)
    .map((word) => {
      if (word.length < 2) return word.toUpperCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
};