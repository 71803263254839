import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { authLogin, closePopup } from "../../store/slices/userAuthSlice";
import { IAuthValueProps } from "../../store/slices/userAuthSlice/interfaces";

import { AuthPage } from '@dataopera/dto-ui';
import { useEffect } from "react";
import { StyledContainer } from "./style";

export default function LoginPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(closePopup());
  }, []);

  const handleLogin = (jwt: string) => {
    const response = dispatch(authLogin(jwt)) as { payload: IAuthValueProps }
    if (response) navigate('/');
  };

  return (
    <StyledContainer>
      <AuthPage authApiBaseUrl={process.env.REACT_APP_API_AUTH_URL} handleLogin={handleLogin}  systemName="DataMea" />
    </StyledContainer>
  )
  
}
