import { Icon } from "../icon";
import { IconsTypes } from "../icon/data";
import { StyledContainer } from "./style";
import { InfosCardInterface } from "./types/InfosCard";

export const InfosCard = ({ inconType, title, value }: InfosCardInterface) => {
  
  const types = {
    'emergency': 'crutch',
    'others': 'prescription-bottle',
    'exam': 'medkit',
    'admission': 'procedures',
    'regulation': 'scale-balanced',
    'procedure': 'procedures',
  }
  
  return (
    <StyledContainer>
      <div className="iconDiv">
        <Icon icon={types[inconType] as IconsTypes} color="#558eff" type="solid" />
      </div>
      <div className="infosDiv">
        <p>{title.toUpperCase()}</p>
        <span><strong>{value}</strong></span>
      </div>
    </StyledContainer>
  );
};
