import { IconTypes } from "../types/Icon";

/**
 * Function to return icon type based on font-awesome v5
 *
 * @param type
 * @returns icon type based on font-awesome v5
 */
export function getIcon({ type }: IconTypes): string {
  const iconOptions = {
    ambulatory: "far fa-hospital",
    inpatient: "fas fa-stretcher",
    emergency: "fas fa-plus-circle",
    observation: "fas fa-notes-medical",
    virtual: "fas fa-laptop-medical",
    homehealth: "fas fa-home",
    'doctor-message': "fas user-doctor-message",
  };

  return iconOptions[type];
}
