import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useAppSelector } from "../../store";

type SocketContextType = {
  connectionStatus?: string;
  lastMessage: MessageEvent<any> | null;
  message: MessageEvent<any> | null;
  messageHistory: any[];
  readyState?: ReadyState;
  handleSetMessage(item: MessageEvent<any> | null): void;
};

const SocketContext = createContext<SocketContextType>({
  connectionStatus: undefined,
  lastMessage: null,
  message: null,
  messageHistory: [],
  readyState: undefined,
  handleSetMessage: () => {},
});

type PropsSocketProvider = {
  children: ReactNode;
};

const SocketProvider: React.FC<PropsSocketProvider> = ({ children }) => {
  const DATAMEA_API_WS_URL = `${process.env.REACT_APP_API_WS_URL}`;
  const { bearerToken } = useAppSelector((state) => state.user);
  const [messageHistory, setMessageHistory] = useState<any[]>([]);
  const [message, setMessage] = useState<MessageEvent<any> | null>(null);
  
  const { lastMessage, readyState } = useWebSocket(
    DATAMEA_API_WS_URL,
    { protocols: bearerToken },
    Boolean(bearerToken)
  );

  const handleSetMessage = useCallback(
    (item: MessageEvent<any | null>) => setMessage(item),
    []
  );

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev: any[]) => prev.concat(lastMessage));
      setMessage(lastMessage);
    }
  }, [lastMessage, setMessageHistory]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const contextValue = useMemo(
    () => ({
      connectionStatus,
      lastMessage,
      message,
      messageHistory,
      readyState,
      handleSetMessage,
    }),
    [
      connectionStatus,
      lastMessage,
      message,
      messageHistory,
      readyState,
      handleSetMessage,
    ]
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };

