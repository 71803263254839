import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export function shouldGoBackToLogin(error: any) {

  if (error && error.response && error.response.data && error.response.status !== 200) return true;

  return false;

}