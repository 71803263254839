import { Copyright } from '@dataopera/dto-ui';
import { Animations, Image, Loading, Section } from "../../components";
import { useClientConfig } from "../../hooks/useClientConfig";

export type ILoadingPageProps = {
  loadingOpacity?: 1 | 0;
};

export const LoadingPage = ({ loadingOpacity = 1 }: ILoadingPageProps) => {
  const { logo } = useClientConfig();

  return (
    <Animations.opacity.expansion>
      <Section.Root
        hg={100}
        gap={0}
        loadingOpacity={loadingOpacity}
        sectionFooter={<Copyright />}
        style={{
          position: "fixed",
          zIndex: 99999999999,
          backgroundColor: loadingOpacity === 0 ? "#00000030" : "",
        }}
      >
        {loadingOpacity === 1 && (
          <Section.Header align="left">
            <Image
              style={{ width: logo.size }}
              url={logo.url}
              alt="Image do Site (DTO)"
            />
          </Section.Header>
        )}

        <Section.Content
          flex={1}
          align={{ spacing: "center", position: "center" }}
        >
          <Loading
            type="one"
            color="#558EFF"
            backgroundColor="transparent"
            size="big"
          />
        </Section.Content>
      </Section.Root>
    </Animations.opacity.expansion>
  );
};
