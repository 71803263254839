import { IRootPopupProps } from "./interfaces";
import * as S from "./styled";

export const RootPopup = ({ children, ...rest }: IRootPopupProps) => {
    return (
        <S.Container {...rest} >
            <S.Content {...rest} >
                {children}
            </S.Content>
        </S.Container>
    )
};