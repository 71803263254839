import { ContentPopup } from "./content";
import { FooterPopup } from "./footer";
import { HeaderPopup } from "./header";
import { RootPopup } from "./root";

export const Popup = {
    Root: RootPopup,
    Header: HeaderPopup,
    Content: ContentPopup,
    Footer: FooterPopup,
};