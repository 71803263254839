import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VerticalTimeLineItem } from "../../Types/VerticalTimeline";
import { InfosInterface } from "../../pages/Summary";
import { api, shouldGoBackToLogin } from "../../services/api";
import { IPatientDataProps } from "../../store/slices/userAuthSlice/interfaces";
import {
  AuthProviderData,
  AuthProviderProps,
  TableContentInterface,
  TableInterface,
} from "./types";

export const RequestsContext = createContext<AuthProviderData>(
  {} as AuthProviderData
);

export const RequestsProvider = ({ children }: AuthProviderProps) => {
  const [headerInfos, setHeaderInfos] = useState<InfosInterface[]>([]);
  const [tableData, setTableData] = useState<TableInterface[]>([]);
  const [timeLineItems, setTimeLineItems] = useState<VerticalTimeLineItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleGetHeaderSlots = async (publicToken: string) => {
    try {
      if (!publicToken) return;

      const { data } = (await api.get(
        `/summary/encounter/total-numbers/${publicToken}`
      )) as { data: InfosInterface[] };

      setHeaderInfos(data);
    } catch (error) {
      console.error({ error });
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        console.log(error);
        sessionStorage.clear();
        navigate("/auth/login");
      }
    }
  };

  async function getTableData(
    src: TableContentInterface[],
    patientToShow: IPatientDataProps
  ) {
    try {
      if (!patientToShow) return;

      let publicToken = "";

      publicToken = patientToShow.mpiKey as string;

      const tableData: TableInterface[] = [];
      
      for (let ind in src) {
        const elm = src[ind];

        if (elm.componentType === "table") {
          const initialDataTable = {
            headers: [],
            rows: [],
            isBlank: false,
            data: [],
            meta: undefined,
            req: {
              isLoading: true,
              datasource: elm.datasource, 
              params: elm.params,
              tableCode: elm.tableCode,
              publicToken
            }
          }

          tableData.push({
            ...initialDataTable,
            title: elm.title,
            isChart: false,
            subtitle: elm.subtitle as string,
            dataSrc: elm.datasource,
            position: elm.position,
          });
        } else if (elm.componentType === "chart") {

          const initialDataChart = {
            headers: [],
            isBlank: false,
            rows: [],
            data: [],
            meta: undefined,
            req: {
              isLoading: true,
              datasource: elm.datasource, 
              params: elm.params,
              tableCode: elm.tableCode,
              publicToken
            }
          }

          tableData.push({
            ...initialDataChart,
            title: elm.title,
            isChart: true,
            subtitle: elm.subtitle as string,
            dataSrc: elm.datasource,
            position: elm.position,
          });

        } else if (elm.componentType === "blank") {
          const blankMock = {
            headers: [],
            rows: [[]],
            title: "",
            meta: undefined,
            subtitle: "",
            isChart: false,
            dataSrc: "",
            req: {
              isLoading: true,
              datasource: elm.datasource, 
              params: elm.params,
              tableCode: elm.tableCode,
              publicToken
            }
          };

          tableData.push({
            ...blankMock,
            position: elm.position,
            isBlank: true,
          });
        }

        setTableData([...tableData]);
      }
    } catch (error) {
      console.error("Error", error);
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        sessionStorage.clear();
        navigate("/auth/login");
      }
    }
  }

  async function getTimelineData(token: string) {
    try {
      setLoading(true);

      const { data } = await api.get(`/feed/${token}`);

      setTimeLineItems(data);
      setLoading(false);
    } catch (error) {
      console.error({ error });
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        sessionStorage.clear();
        navigate("/auth/login");
      }
      setLoading(false);
    }
  }

  return (
    <RequestsContext.Provider
      value={{
        getTimelineData,
        handleGetHeaderSlots,
        getTableData,
        headerInfos,
        tableData,
        timeLineItems,
        setTimeLineItems,
        loading,
        setLoading,
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequests = () => useContext(RequestsContext);
