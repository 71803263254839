import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mui/material";
import { FormEvent, InputHTMLAttributes, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../../../../providers/Auth";
import ListUsersModal from "./components/ListUsersModal";
import { StyledContainer } from "./style";


interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
  onSearch: (val: string) => void;
}


export const Input = ({ onSearch, ...rest }: InputInterface) => {
  const inputRef = useRef<any | null>(null);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const { searchedUsers, handleGetUserDetails, setSearchedUsers, loadingSearch } = useAuth();

  const handleSubmit = async () => {
    if (inputRef.current.value.length > 0) {
      onSearch(inputRef.current.value);

      setShowSearchResults(true);
      return;
    } else {
      closeModal();
      return;
    }
  };

  function closeModal() {
    setShowSearchResults(false);
    setSearchedUsers([]);
    inputRef.current.value = "";
  }

  function selectUser(token: string) {
    handleGetUserDetails(token);
    closeModal();
  }

  return (
    <>
      <StyledContainer className="styled-input input" onClick={() => inputRef.current.focus()}>
        {loadingSearch ? <Spinner style={{width: "20px", height: "20px", marginLeft: "10px", color: "#CACCCF"}} animation="border" /> : <FontAwesomeIcon icon={faMagnifyingGlass} />}
        <form
          onSubmit={(event: FormEvent) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <input ref={inputRef} {...rest} type="text" />
        </form>
      </StyledContainer>

      {showSearchResults && searchedUsers.length > 0 && (
        <Modal
          open={showSearchResults}
          onClose={closeModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <>
            <ListUsersModal
              onCloseCallback={() => closeModal()}
              selectUserCallBack={selectUser}
              searchedUsers={searchedUsers}
            />
          </>
        </Modal>
      )}
    </>
  );
};
