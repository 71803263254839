import { ReactNode } from "react";
import { useClientConfig } from "../hooks/useClientConfig";
import { SettingsProvider } from "../utils/useLocalStorage/settingsProvider";
import { AuthProvider } from "./Auth";
import { RequestsProvider } from "./Requests";

interface ProviderProps {
  children: ReactNode;
}

const Providers = ({ children }: ProviderProps) => {

  const defaultSettings = useClientConfig();

  return (
    <AuthProvider>
      <RequestsProvider>
        <SettingsProvider defaultSettings={defaultSettings} >{children}</SettingsProvider>
      </RequestsProvider>
    </AuthProvider>
  );
};

export default Providers;
