import styled from "styled-components";

const StyledListUsersModalContainer = styled.div`
  background-color: var(--color-white);

  // Mobile version
  width: 300px;
  height: 500px;

  // Tablet Version
  @media (min-width: 768px) {
    width: 600px;
  }

  // Small Laptop Version
  @media (min-width: 1024px) {
    width: 800px;
  }

  // Laptop Version
  @media (min-width: 1440px) {
    width: 950px;
  }

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  border-radius: 10px;

  // Lista de pacientes
  .listUsers-modal_patients {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    overflow-x: scroll;
    height: calc(100% - 50px);
  }
`;

const StyledListUsersModalHeader = styled.div`
  width: 100%;
  background-color: #3d79f2;

  color: #e8e8e8;

  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .listUsers-modal_title {
    margin-left: 10px;
    font-size: 1.1rem;
  }

  .listUsers-modal_closeModal {
    cursor: pointer;
    margin-right: 20px;

    i {
      font-size: 24px;
    }
  }
`;

export { StyledListUsersModalContainer, StyledListUsersModalHeader };

