import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    :root{
        --color-primary: #25282B;
        --color-secondary: #558EFF;
        --color-secondary-light: #558EFF10;
        --color-background: #F6F8FB;
        --color-grayDark: #52575C;
        --color-grayLight: #CACCCF;
        --color-placeHolder: #A0A4A8;
        --color-red: #FF6760;
        --color-white: #ffffff;

        --color-secondaryText: #202223;
        --color-neutralBlack: #25282B;

        --color-status-pending: #FFB800;
        --color-status-excluded: #FF0000;
        --color-status-sucess: #5BD175;
        --color-status-monitored: #00D1FF;

        --color-timeline-atribute: #3B9BD2;
        --color-timeline-atribute-content: #707070;
        --color-timeline-notes: #858585;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
    	margin: 0;
    	padding: 0;
    	border: 0;
    	vertical-align: baseline;
    }

    footer, header, hgroup, menu, nav, section {
	display: block;
    }
    body {
    	line-height: 1;
    }
    ol, ul {
    	list-style: none;
    }
    body{
        background-color: var(--color-background); 

        font-family: 'Lato', sans-serif;
    }
    button{
        cursor: pointer;
    }

    #header {
        z-index: 900;
        transition: top 0.3s ease;
    }

    .no-scroll #header {
        display: none;
    }

    .nav-up #header {
        top: -180px;
        @media (min-width: 768px){
            top: -90px;   
        }
    }

    .css-1cwfna8-MuiTableCell-root{
        line-height: 1 !important;
    }

    ::-webkit-scrollbar-track {
    background-color: #F6F8FB;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: #F6F8FB;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #A0A4A8;
        border-radius: 5px;
    }
`;

export default GlobalStyles;
