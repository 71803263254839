import styled from "styled-components";
import { ITextAreaStyledProps } from "./interfaces";

export const TextArea = styled.textarea<ITextAreaStyledProps>`
    outline: none;
    border: none;

    color: ${({ txtColor = '#000000' }) => txtColor};

    font-size: ${({ txtFontSize = 1 }) => `${txtFontSize}rem`};
    
    width: 100%;
    height: 100%;

    padding: ${({ txtPad = '15px' }) => txtPad};
    border-radius: ${({ txtRadius = '10px' }) => txtRadius};

    background-image: ${({ 
        txtBgGradient = { 
            init: '#DDE6F299', 
            end: 'rgb(246, 248, 251)' 
        } 
    }) => `linear-gradient(${txtBgGradient.init},${txtBgGradient.end})`};

    display: flex;
    flex: 1;

    * {
        white-space: pre-wrap;
    }
    
    &:focus {
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
    }
`;