import { ListItemText, Modal } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import { Icon } from "../../../icon";
import { ISettingsModalProps } from "./interfaces";
import * as S from "./styled";
const { version } = require("../../../../../package.json");

export const HelpModal = ({ onClose, isOpen }: ISettingsModalProps) => {
  const styleListItem = {
    borderBottom: "solid 1px #00000020",
    cursor: "pointer",
    transition: "all 0.3s",
    ":hover": {
      backgroundColor: "#558eff20",
    },
    ":active": {
      backgroundColor: "#558eff60",
    },
  };

  const handleOpenLink = (url: string) => window.open(url);

  return (
    <Modal
      style={{ outline: "none" }}
      onClose={onClose}
      open={isOpen}
      sx={S.ModalStyled}
    >
      <List
        style={S.ListStyled}
        subheader={
          <ListSubheader sx={S.ListHeader}>
            <h2 style={{ fontSize: "1.2rem" }}>Ajuda</h2>
            <Icon
              type="solid"
              handleClick={onClose}
              cursor="pointer"
              icon="close"
            />
          </ListSubheader>
        }
      >
        <ListItem sx={styleListItem} onClick={() => handleOpenLink("mailto:atendimento@dataopera.com.br")} >
          <ListItemIcon>
            <Icon
              type="solid"
              customStyleds={{ fontSize: "1.25rem" }}
              icon="headset"
              color="#0000008a"
            />
          </ListItemIcon>
          <ListItemText primary="Fale Conosco" />
        </ListItem>

        <ListItem sx={styleListItem} onClick={() => handleOpenLink("https://dataopera.com.br/politica-de-privacidade/")} >
          <ListItemIcon>
            <Icon
              type="solid"
              customStyleds={{ fontSize: "1.25rem" }}
              icon="file-alt"
              color="#0000008a"
            />
          </ListItemIcon>
          <ListItemText primary="Termos e Politica de Privacidade" />
        </ListItem>

        <ListItem sx={styleListItem} onClick={() => handleOpenLink("https://dataopera.docs.apiary.io/#reference")} >
          <ListItemIcon>
            <Icon
              type="solid"
              customStyleds={{ fontSize: "1.25rem" }}
              icon="file-alt"
              color="#0000008a"
            />
          </ListItemIcon>
          <ListItemText primary="Manual de Treinamento" />
        </ListItem>
        <ListItem sx={{ height: '25px', marginTop: '20px', textAlign: 'center' }} >
          <ListItemText primary={`versão ${version}`} />
        </ListItem>
      </List>
    </Modal>
  );
};
