import moment from "moment";
import { useEffect, useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { VerticalTimeLineItem } from "../../../Types/VerticalTimeline";
import { TableContainer } from "../../../pages/Summary/components/TableContainer";
import { getIcon } from "./utils/GetIcons";
import { TableSkeletons } from "../../../pages/Summary/components/TableSkeletons";

interface TimeLineItemInterface {
  item: VerticalTimeLineItem;
  index: number;
}

export const TimeLineItem = ({ item, index }: TimeLineItemInterface) => {

  const [ isDiplayNone, setIsDiplayNone  ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDiplayNone(true)
    },  4000 * index);
  }, []);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: "#fff", color: "#000", boxShadow: '2px 2px 8px #00000040' }}
      date={`${moment(item.date).format("l")} ${moment(item.date).format("LT")}`}
      iconStyle={{ background: "rgb(33, 150, 243)", color: "#E9F7FE" }}
      icon={<i className={getIcon({ type: item.class })} />}    
    >
      <div className="vertical-timeline-item-main-infos">
        <h4>Profissional</h4>
        <p>{item.participant && item.participant.display ? item.participant.display : "Não Informado"}</p>
      </div>

      <div className="vertical-timeline-item-main-infos">
        <h4>Origem da informação</h4>
        <p>{item.source}</p>
      </div>

      <div className="vertical-timeline-item-main-infos">
        <h4>Local</h4>
        <p>{item.local ? item.local : "Não Informado"}</p>
      </div>

      <div className="vertical-timeline-item-main-infos">
        <h4>Início</h4>
        <p>
          {moment(item.period.start).format("L")} {moment(item.period.start).format("LT")}
        </p>
      </div>

      <div className="vertical-timeline-item-main-infos">
        <h4>Fim</h4>
        <p>
          {moment(item.period.end).format("L")} {moment(item.period.end).format("LT")}
        </p>
      </div>

      {
        !isDiplayNone ? 
        ( <TableSkeletons  />      ) 
        :
        (
          <TableContainer isTimeLineView encounterId={item.id.split('/')[1]} sx={{ fontSize: '14px', tableColor: '#7db2d1' }} />
        )         
        
      }

      {/* {item.content?.map((content, ind) => (
        <div key={ind} className="vertical-timeline-item-container">
          <h4>{content.Title}</h4>          
          {content.items?.map((newItem, key) => (
            <div key={key}>
              {newItem.componentType === "1" && (
                <TimeLineItemType1 key={key} item={newItem} date={newItem.date && newItem.date} />
              )}
              {newItem.componentType === "2" && (
                <TimeLineItemType2 key={key} item={newItem} date={newItem.date && newItem.date} />
              )}
              {newItem.componentType === "3" && (
                <TimeLineItemType3 key={key} item={newItem} date={newItem.date && newItem.date} />
              )}
            </div>
          ))}
        </div>
      ))} */}
    </VerticalTimelineElement>
  );
};
