import "bootstrap/dist/css/bootstrap.min.css";
import { SnackbarProvider } from 'notistack';
import { useEffect } from "react";
import { useClearCache } from 'react-clear-cache';
import { Provider } from "react-redux";
import "./app.css";
import { SocketProvider } from "./contexts/Socket/socket";
import Providers from "./providers";
import Router from "./routes";
import { Store } from "./store";
import GlobalStyles from "./styles/global";

function App() {

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) emptyCacheStorage();
  }, []);

  return (
    <SnackbarProvider>
      <Provider store={Store}>
        <Providers>
          <SocketProvider>
            <GlobalStyles />
            <Router />
          </SocketProvider>
        </Providers>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
