const storagePrefix = "@data-mea:";

const storage = {
  getToken: () => {
    return JSON.parse(sessionStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    sessionStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    sessionStorage.removeItem(`${storagePrefix}token`);
  },
  getRefreshToken: () => {
    return JSON.parse(sessionStorage.getItem(`${storagePrefix}refreshToken`) as string);
  },
  setRefreshToken: (token: string) => {
    sessionStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
  },
  clearRefreshToken: () => {
    sessionStorage.removeItem(`${storagePrefix}refreshToken`);
  },
  logOut: () => {
    sessionStorage.clear();
  },
};

export default storage;
