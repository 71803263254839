import { Modal } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import { useSettingsContext } from "../../../../utils/useLocalStorage/settingsContext";
import { Icon } from "../../../icon";
import { ISettingsModalProps } from "./interfaces";
import * as S from "./styled";

export const SettingsModal = ({ onClose, isOpen }: ISettingsModalProps) => {
  const { sortTablesWithoutData, onUpdate } = useSettingsContext();

  return (
    <Modal
      style={{ outline: "none" }}
      onClose={onClose}
      open={isOpen}
      sx={S.ModalStyled}
    >
      <List
        style={S.ListStyled}
        subheader={
          <ListSubheader sx={S.ListHeader}>
            <h2 style={{ fontSize: "1.2rem" }}>Preferências da Aplicação</h2>
            <Icon
              type="solid"
              handleClick={onClose}
              cursor="pointer"
              icon="close"
            />
          </ListSubheader>
        }
      >
        <ListItem sx={{ borderBottom: "solid 1px #00000020" }}>
          <ListItemIcon>
            <Icon
              type="solid"
              customStyleds={{ fontSize: "1.25rem" }}
              icon="sort-size-down-alt"
              color="#0000008a"
            />
          </ListItemIcon>
          <ListItemText primary="Posicionar tabelas sem dados ao Fim da página?" />
          <Switch
            edge="end"
            onChange={() =>
              onUpdate("sortTablesWithoutData", sortTablesWithoutData === "Bottom" ? "Default" : "Bottom")
            }
            checked={sortTablesWithoutData === "Bottom" ? true : false}
          />
        </ListItem>
      </List>
    </Modal>
  );
};
