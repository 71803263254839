import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { validatePhone } from "validations-br";
import * as yup from "yup";
import { api } from "../../../../services/api";
import { useAppSelector } from "../../../../store";
import { StyledButtonContainer, StyledContainer, StyledLabItem } from "./style";
import { LabsInterface } from "./types";

export default function RequestExam({
  exam,
  code,
  closeFunc,
}: {
  code: string;
  exam: string;
  closeFunc: () => void;
}) {
  const [loading, setLoading] = useState(true);
  const [seletecLab, setSelectedLab] = useState<{
    name: string;
    idLaboratory: string;
  } | null>();
  const [labs, setLabs] = useState<LabsInterface[]>([]);
  const [errorLabs, setErrorLabs] = useState("");

  const { patientToShow } = useAppSelector((state) => state.user);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email é obrigatório")
      .required("Email é obrigatório"),
    phone: yup
      .string()
      .required("Telefone é obrigatório")
      .test("is-phone", "Telefone inválido", (value: any) =>
        validatePhone(value)
      ),
    comment: yup.string(),
    name: yup.string().required(),
    doc: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function getLab() {
    try {
      setLoading(true);
      const { data } = await api.get("/summary/schedule/laboratories");

      setLabs(data);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const onSubmitHandler = async (scheduleData: any) => {
    if (!seletecLab) {
      setErrorLabs("Selecione um laboratório");
      return;
    }

    const newData = {
      Requester: {
        Name: scheduleData.name,
        CredentialNumber: scheduleData.doc,
      },
      Patient: {
        Name: patientToShow?.nome,
        Document:
          patientToShow["doc-cpf"] ??
          patientToShow["doc-rg"],
      },
      Exam: {
        Name: exam,
        Comments: scheduleData.comment,
        TUSSCode: code,
      },
      Laboratory: {
        id: seletecLab.idLaboratory,
        Name: seletecLab.name,
      },
      Share: {
        email: scheduleData.email,
        phoneNumber: scheduleData.phone,
      },
    };

    await api.post("/summary/schedule/examRequest", {
      ...newData,
    });

    closeFunc();
  };

  useEffect(() => {
    getLab();

    return () => {
      setLoading(true);
      setLabs([]);
    };
  }, [exam]);

  return (
    <StyledContainer >
      <div className="sides-container">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="sides-container-in">
            <div className="left-side">
              <div className="children-container">
                <span className="exam-name">{exam}</span>
              </div>
              <div className="children-container">
                <TextField
                  placeholder="Digite seu email"
                  {...register("email")}
                  type="email"
                  error={!!errors.email}
                  variant="standard"
                  label="Email"
                  helperText={errors.email ? "Email incorreto" : undefined}
                />
                <TextField
                  placeholder="Digite seu telefone"
                  {...register("phone")}
                  error={!!errors.phone}
                  variant="standard"
                  label="Telefone"
                  helperText={errors.phone ? "Telefone incorreto" : undefined}
                />
              </div>
              <div className="children-container">
                <TextField
                  placeholder="Digite seu nome"
                  {...register("name")}
                  error={!!errors.name}
                  variant="standard"
                  label="Nome"
                />
                <TextField
                  placeholder="Digite seu cpf ou rg"
                  {...register("doc")}
                  error={!!errors.doc}
                  variant="standard"
                  label="Documento"
                />
              </div>

              <TextField
                fullWidth
                multiline
                rows={5}
                placeholder="Deixe uma anotação..."
                {...register("comment")}
                error={!!errors.phone}
                variant="outlined"
                label="Anotações"
              />
            </div>

            <div className="right-side">
              <div className="select-container">
                <p className={errorLabs ? "error-message" : ""}>
                  Laboratório:{" "}
                  <span>
                    {
                      labs.find(
                        (elm) => elm.idLaboratory === seletecLab?.idLaboratory
                      )?.Name
                    }
                  </span>
                </p>
                {!loading && (
                  <div className="labs-container">
                    <Stack>
                      {labs.map((lab, ind) => (
                        <StyledLabItem
                          active={seletecLab?.idLaboratory === lab.idLaboratory}
                          onClick={() => {
                            if (seletecLab?.idLaboratory === lab.idLaboratory) {
                              setSelectedLab(null);
                            } else {
                              setSelectedLab({
                                name: lab.Name,
                                idLaboratory: lab.idLaboratory,
                              });
                              setErrorLabs("");
                            }
                          }}
                          className="lab-item"
                          key={ind}
                        >
                          {lab.Name}
                        </StyledLabItem>
                      ))}
                    </Stack>
                  </div>
                )}

                {loading && (
                  <div style={{ marginTop: "30px" }}>
                    <Spinner
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#CACCCF",
                      }}
                      animation="border"
                    />
                  </div>
                )}
                <span className="error-message" role="alert">
                  {!!errorLabs && errorLabs}
                </span>
              </div>
            </div>
          </div>

          <StyledButtonContainer>
            <button className="btn-danger" type="button" onClick={closeFunc}>
              Cancelar
            </button>
            <button type="submit" className="btn-success">
              Confirmar
            </button>
          </StyledButtonContainer>
        </form>
      </div>
    </StyledContainer>
  );
}
