import styled from "styled-components";
import { IContentPopupStyledProps } from "./interfaces";

export const Container = styled.div<IContentPopupStyledProps>`
    padding: 20px;
    width: 100%;
    
    display: flex;
    flex: 1;

    border-radius: ${({ bdRadius = '0' }) => {
        if (typeof bdRadius === 'string') return `${bdRadius}`
        else return `${bdRadius}rem`;
    }};

    text-align: center;
    color: ${({ txtColor = 'black' }) => txtColor};

    background-color: ${({ bgColor = '#ffffff' }) => bgColor};

    align-items: center;
    justify-content: center;
`;