import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { TableSkeletons } from "../../../pages/Summary/components/TableSkeletons";
import {
  ChartItemInterface,
  IReqDataTables,
} from "../../../providers/Requests/types";
import { api } from "../../../services/api";
import { useSettingsContext } from "../../../utils/useLocalStorage/settingsContext";
import { Tooltip } from "../../Tooltip";
import { Icon } from "../../icon";
import { IconsTypes } from "../../icon/data";
import EmptyTable from "../EmptyTable";
import { HeaderContainer, TableWrapper } from "../NewTable/style";
import {
  ContainerTitle,
  GraphWrapper,
  SubtitlesContainer,
  TitleContainer,
} from "./style";

type ChartData = {
  data: ChartItemInterface[];
  req: IReqDataTables;
  title: string;
  subtitle: string;
  position?: number;
  openModal: () => void;
  isFull?: boolean;
  iconType: IconName;
};

export const NewChartTable = ({
  req,
  data,
  title,
  subtitle,
  position,
  openModal,
  iconType,
  isFull,
}: ChartData) => {
  const [isLoading, setIsLoading] = useState(req.isLoading);
  const [tableData, setTableData] = useState<ChartItemInterface[]>([]);

  const getTableData = async () => {
    try {

      let dataURL = ''; 

      if (req.params) {
        if (req.tableCode) dataURL = `/${req.datasource}/${req.publicToken}/${req.tableCode}?${req.params}`;
        else dataURL = `/${req.datasource}/${req.publicToken}?${req.params}`
      }
      else if (req.tableCode) dataURL = `/${req.datasource}/${req.publicToken}/${req.tableCode}`;
      else dataURL = `/${req.datasource}/${req.publicToken}`;

      const { data } = await api.get(dataURL);
      
      setTableData(data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getTableData();
  }, [req]);

  const existDataTable = tableData?.length < 1 && !isLoading;
  
  const { sortTablesWithoutData } = useSettingsContext();

  return (
    <>
      <TableWrapper isFull={isFull} order={existDataTable && sortTablesWithoutData === 'Bottom' ? position : -99999}>
        <HeaderContainer id="header">
          <div className="container-wrapper">
            <ContainerTitle>
              { iconType &&  <Icon icon={iconType as IconsTypes} type="solid" /> } 
              <TitleContainer>
                <h1>{title}</h1>
                <Tooltip description={subtitle}>
                  <Icon cursor="pointer" type="light" icon={"info-circle"} />
                </Tooltip>
              </TitleContainer>
              <Tooltip
                description="Visualizar em Tela Cheia"
                positionInitial="row-reverse"
              >
                <Icon
                  handleClick={openModal}
                  cursor="pointer"
                  type="solid"
                  icon={"expand-alt"}
                />
              </Tooltip>
            </ContainerTitle>
          </div>
        </HeaderContainer>
        
        
        {existDataTable && (<EmptyTable title={title} />)}

        {
          isLoading && (<TableSkeletons />)
        }

        {!!tableData.find((item) => item.value > 0) && (
          <>
            <GraphWrapper>
              <PieChart
                animate
                label={({ dataEntry }) => dataEntry.value}
                labelStyle={(_) => ({
                  fill: "#000",
                  fontSize: "5px",
                  fontFamily: "sans-serif",
                })}
                radius={42}
                labelPosition={112}
                data={tableData}
              />
            </GraphWrapper>

            <SubtitlesContainer className="subtitles-div">
              <p className="subtitle">Legenda:</p>
              <div>
                {tableData.map(
                  (elm, key) =>
                    elm && (
                      <div key={key} className="color">
                        <span style={{ backgroundColor: `${elm.color}` }} />
                        <p>{elm.title}</p>
                      </div>
                    )
                )}
              </div>
            </SubtitlesContainer>
          </>
        )}
      </TableWrapper>
    </>
  );
};
