import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useRequests } from "../../providers/Requests";
import { TimeLineItem } from "./TimeLineItem";
import "./styled.css";

export const TimeLineComponent = () => {
  const { timeLineItems } = useRequests();

  return (
    <VerticalTimeline className="force-css-vertical-timeline" lineColor="#CACCCF" layout="1-column-left">
      {timeLineItems?.map((item, ind) => (
        <TimeLineItem key={ind} item={item} index={ind} />
      ))}
    </VerticalTimeline>
  );
};
