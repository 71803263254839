import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { IAuthLogin } from "../../../providers/Auth/types";
import storage from "../../../utils/storage";
import { IAuthValueProps, IPatientDataProps, IUserAuth } from "./interfaces";
import { refreshTokenThunk, userLoginThunk } from "./thunks";
import { userLogoutThunk } from "./thunks/logoutThunk";

const initialState: IUserAuth = {
  isLogged: false,
  isDoctor: false,
  patientToShow: {} as IPatientDataProps,
  token: "",
  shouldBlockUser: false,
  loading: false,
  popup: {
    isOpen: false,
    title: '',
    message: "",
    type: "auto",
  },
  bearerToken: "",
};

export const UserAuthSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authLogin: (state, { payload }) => {
      const { user } = jwtDecode(payload as string) as IAuthLogin;
      
      storage.setToken(payload as string);
      if (user && user.type === 'professional') {
        sessionStorage.setItem("@data-mea:usertype", user.type);
        state.doctorData = user;
        state.isDoctor = true;
        state.shouldBlockUser = false;
        state.patientToShow = {} as IPatientDataProps
        state.isLogged = true;
      };
    },
    closePopup: (state) => {
      state.popup = {
        isOpen: false,
        title: '',
        message: "",
        type: "auto",
      };
    },
    openPopup: (state, { payload }) => {
      state.popup = {
        isOpen: true,
        title: payload.title,
        message: payload.message,
        type: "auto",
      };
    },
    applyDataPatient: (state, { payload }) => {
      const decoded = jwtDecode(payload as string) as IAuthValueProps;

      sessionStorage.setItem(
        "@data-mea:selectedPatientData",
        JSON.stringify(decoded)
      );

      state.patientToShow = decoded.patientData!;
      state.loading = false;
    },
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    },
    toogleLoading: (state, { payload }: { payload?: boolean }) => {
      state.loading = payload ? payload : !state.loading;
    },
    applyDataPageReload: (state, { payload }) => {

      const { patientData } = jwtDecode(payload as string) as IAuthValueProps;

      const selectedPatientData = sessionStorage.getItem(
        "@data-mea:selectedPatientData"
      );

      if (patientData) state.patientToShow = patientData!;
      else if (selectedPatientData) state.patientToShow = JSON.parse(selectedPatientData);

      if (sessionStorage.getItem("@data-mea:usertype") === "professional") {
        state.isDoctor = true;
        state.shouldBlockUser = false;
      }

      if (patientData?.mpiKey) {
        sessionStorage.setItem(
          "@data-mea:publicToken",
          JSON.stringify(patientData?.mpiKey)
        );
      };

      state.isLogged = true;
    },
  },

  extraReducers(builder) {

    builder.addCase(userLogoutThunk.rejected, (state, action) => {
      state.popup = {
        isOpen: true,
        title: 'Erro Desconhecido!',
        message:
          "Erro desconhecido! Tente novamente mais tarde ou contate um administrador do sistema.",
        type: "error",
      };
    });

    builder.addCase(userLogoutThunk.fulfilled, (state, action) => {
      sessionStorage.removeItem("@Auth:token");
      state.isLogged = false;
    });

    builder.addCase(userLoginThunk.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(userLoginThunk.rejected, (state, action) => {
      state.loading = false;
      state.popup = {
        isOpen: true,
        title: 'Erro Desconhecido!',
        message:
          "Erro desconhecido! Tente novamente mais tarde ou contate um administrador do sistema.",
        type: "error",
      };
    });

    builder.addCase(refreshTokenThunk.rejected, (state, action) => {
      state.loading = false;
      state.popup = {
        isOpen: true,
        title: 'Erro Desconhecido!',
        message:
          "Erro desconhecido! Tente novamente mais tarde ou contate um administrador do sistema.",
        type: "error",
      };
    });

    builder.addCase(refreshTokenThunk.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(userLoginThunk.fulfilled, (state, action) => {
      const { type, message, value } = action.payload;

      if (type === 'patient-not-found') {
        state.loading = false;
        state.popup = {
          isOpen: true,
          title: 'Paciente não localizado',
          message,
          type: "auto",
        };
      };

      if (type === 'invalid-api-key') {
        state.loading = false;
        state.popup = {
          isOpen: true,
          title: 'Token de Autenticação inválido',
          message,
          type: "auto",
        };
      };


      if (type === 'consentment-denied') {
        state.loading = false;
        state.popup = {
          isOpen: true,
          title: 'Paciente não deu Consentimento!',
          message,
          type: "auto",
        };
      }

      if (type === "unknown-error") {
        state.loading = false;
        state.popup = {
          isOpen: true,

          title: 'Erro Desconhecido!',
          message,
          type: "error",
        };
      }

      if (type === "authentication-error") {
        state.loading = false;
        state.popup = {
          isOpen: true,
          title: 'Erro Desconhecido!',
          message,
          type: "error",
        };
      }

      if (action.payload.type === "success") {
        const decoded = jwtDecode(value as string) as IAuthValueProps;

        state.bearerToken = value!;
        sessionStorage.setItem("@data-mea:usertype", decoded?.user.type as string);

        console.log("decoded", decoded)

        if (decoded?.user.type === "patient") {
          state.isDoctor = false;
          state.patientToShow = decoded.patientData!;
          state.shouldBlockUser = false;
        }

        if (decoded?.user.type === 'professional') {
          state.isDoctor = true;
          state.shouldBlockUser = false;
          state.patientToShow = {} as IPatientDataProps
          state.isLogged = true;
        };

        if (decoded && decoded.patientData) {
          sessionStorage.setItem(
            "@data-mea:publicToken",
            JSON.stringify(decoded.patientData.mpiKey)
          );
        }

        storage.setToken(value as string);
        storage.setRefreshToken(value as string);

        state.doctorData = decoded.user
        state.patientToShow = decoded?.patientData!;
        state.isLogged = true;
        state.loading = false;
      }
    });
  },
});

export const UserReducer = UserAuthSlice.reducer;
export const {
  closePopup,
  applyDataPageReload,
  authLogin,
  openPopup,
  applyDataPatient,
  toogleLoading,
} = UserAuthSlice.actions;
