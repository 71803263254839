import styled from "styled-components";

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  overflow: hidden !important;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 999;
  
  max-height: 570px;
  width: 1000px;
  max-width: 95%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
`;

export const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

export const ModalClose = styled.span`
  font-size: 1.5rem;
  cursor: pointer;
`;
