import { Copyright } from '@dataopera/dto-ui';
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerComponent } from "../../components/Container";
import { UserInfosHeader } from "../../components/UserInfosHeader";
import { Popup } from "../../components/popup";
import { useAuth } from "../../providers/Auth";
import { useAppDispatch, useAppSelector } from "../../store";
import { closePopup } from "../../store/slices/userAuthSlice";
import { userLogoutThunk } from "../../store/slices/userAuthSlice/thunks/logoutThunk";
import storage from "../../utils/storage";
import PagesWrapper from "../PageWrapper";
import { LoadingPage } from "../loading";
import { handleGetPage } from "../utils/GetPage";
import { SearchPatientMessage } from "./components/SearchPatient";
import {
  MenuTabsPatientInformationsArea,
  MenuTabsPatientInformationsContent,
  StyledButton,
  StyledLayout,
} from "./styles";
import { UsersViewInterface } from "./types";

/**
 * Função que renderiza maior parte das paginas
 * @returns TSX elements
 */
function Dashboard() {
  const { loadingAuth } = useAuth();
  const { shouldBlockUser, patientToShow, isDoctor, loading } = useAppSelector(
    (state) => state.user
  );

  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const { popup } = useAppSelector((state) => state.user);

  const [selectedButton, setSelectedButton] = useState<
    "Sumario" | "Linha do tempo"
  >("Sumario");

  const returnLogin = () => {
    storage.clearRefreshToken();
    storage.clearToken();
    dispatch(userLogoutThunk());
    navigator("/auth/login");
  };

  return (
    <>
      {loading ? <LoadingPage loadingOpacity={!patientToShow?.mpiKey ? 1 : 0} /> : ""}
      {!!popup?.isOpen && (
        <Popup.Root align="center">
          <Popup.Header>
            <h1 style={{ fontSize: "1.4rem", color: "#ffffff" }}>
              {popup.title}
            </h1>
          </Popup.Header>
          <Popup.Content bdRadius={0.5}>
            <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
              {popup.message}
            </p>
          </Popup.Content>
          <Popup.Footer justifyContent="center">
            <button
              onClick={() => {
                if (isDoctor) {
                  // dispatch(toogleLoading());
                  dispatch(closePopup());
                } else {
                  returnLogin();
                }
              }}
            >
              {isDoctor ? "Buscar por outro paciente" : "Ir para o Login"}{" "}
            </button>
          </Popup.Footer>
        </Popup.Root>
      )}
      <PagesWrapper loadingAuth={loadingAuth} shouldBlockUser={shouldBlockUser}>
        <StyledLayout>
          {/* Renders Page when is done loading */}
          {!loadingAuth && (
            <>
              {isDoctor &&
                DoctorView({
                  selectedButton,
                  setSelectedButton,
                  patientToShow,
                })}
              {!isDoctor &&
                PatientView({
                  selectedButton: selectedButton,
                  setSelectedButton,
                })}
            </>
          )}
          {/* Renders Loading page */}
          {loadingAuth && <LoadingPage loadingOpacity={1} />}
          <br />
          <br />
          <div>
            <Copyright />
          </div>
        </StyledLayout>
      </PagesWrapper>
    </>
  );
}

function PatientView({
  selectedButton,
  setSelectedButton,
}: UsersViewInterface) {
  const { patientToShow } = useAppSelector((state) => state.user);

  const userData = useMemo(() => {
    if (patientToShow) {
      return patientToShow;
    }
  }, [patientToShow]);

  return (
    <>
      <UserInfosHeader
        userData={userData}
        selectedButton={selectedButton}
        changeButton={setSelectedButton}
      />
      <MenuTabsPatientInformationsArea>
        <ContainerComponent>
          <MenuTabsPatientInformationsContent>
            <StyledButton
              disabled={!userData}
              onClick={() => setSelectedButton("Sumario")}
              selected={selectedButton === "Sumario"}
            >
              Sumário
            </StyledButton>
            <StyledButton
              disabled={!userData}
              onClick={() => setSelectedButton("Linha do tempo")}
              selected={selectedButton === "Linha do tempo"}
            >
              Linha do tempo
            </StyledButton>
          </MenuTabsPatientInformationsContent>
        </ContainerComponent>
      </MenuTabsPatientInformationsArea>
      {handleGetPage(selectedButton)}
    </>
  );
}

function DoctorView({
  patientToShow,
  selectedButton,
  setSelectedButton,
}: UsersViewInterface) {
  let publicToken = "";

  const userData = useMemo(() => {
    if (patientToShow?.mpiKey) {
      publicToken = patientToShow.mpiKey;
      return patientToShow;
    }
  }, [patientToShow]);

  if (!patientToShow?.mpiKey) {
    return <SearchPatientMessage />;
  } else {
    return (
      <>
        <UserInfosHeader
          selectedButton={selectedButton}
          userData={userData}
          changeButton={setSelectedButton}
        />
        <MenuTabsPatientInformationsArea>
          <ContainerComponent>
            <MenuTabsPatientInformationsContent>
              <StyledButton
                disabled={!userData}
                onClick={() => setSelectedButton("Sumario")}
                selected={selectedButton === "Sumario"}
              >
                Sumário
              </StyledButton>
              <StyledButton
                disabled={!userData}
                onClick={() => setSelectedButton("Linha do tempo")}
                selected={selectedButton === "Linha do tempo"}
              >
                Linha do tempo
              </StyledButton>
            </MenuTabsPatientInformationsContent>
          </ContainerComponent>
        </MenuTabsPatientInformationsArea>
        {handleGetPage(selectedButton)}
      </>
    );
  }
}

export default Dashboard;
