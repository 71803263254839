import isEqual from 'lodash/isEqual';
import { useCallback, useMemo } from 'react';
import { ISettingsProviderProps, ISettingsValueProps } from './interfaces';
import { SettingsContext } from './settingsContext';
import { useLocalStorage } from './useLocalStorage';

export const SettingsProvider = ({ children, defaultSettings }: ISettingsProviderProps) => {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

  const onUpdate = useCallback(
    (name: string, value: string | boolean) => {
      setSettings((prevState: ISettingsValueProps) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setSettings]
  );

  const onReset = useCallback(() => {
    setSettings(defaultSettings);
  }, [defaultSettings, setSettings]);

  const canReset = !isEqual(settings, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onUpdate,
      canReset,
      onReset,
    }),
    [
      onReset,
      onUpdate,
      settings,
      canReset
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
