import { BlockUser } from "../../components/Blockuser";
import { Header } from "../../components/Header";
import { PageWrapperInterface } from "./types";

function PagesWrapper({
  shouldBlockUser,
  loadingAuth,
  children,
}: PageWrapperInterface) {

  return (
    <>
      {/* Bloqueia o usuario quando ele não tem permissão para ver os dados */}
      {shouldBlockUser && <BlockUser />}

      {/* Renderiza Header quando usuario está logado */}
      {!loadingAuth && <Header />}

      {children}
    </>
  );
}

export default PagesWrapper;
