/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../contexts/Socket/socket";
import { api } from "../../../../services/api";
import { useAppSelector } from "../../../../store";
import { Notifications } from "../../../Notifications";
import {
  IDataNotification,
  MessageNotificationWebSocket,
  WsDatameaTypes,
} from "../../../Notifications/interfaces";

export const NotificationIcon = () => {
  const [notifications, setNotifications] =
    useState<Array<IDataNotification>>();

  const { message } = useContext(SocketContext);

  const { patientToShow } = useAppSelector((state) => state.user);

  let publicToken = "";

  publicToken = patientToShow.mpiKey;

  useEffect(() => {
    if (!message) {
      return;
    }
    try {
      const content = JSON.parse(message.data) as MessageNotificationWebSocket;
      if (content.type !== WsDatameaTypes.ALERT) return;
      const { data } = content;
      const exists = notifications?.find((n) => n.id === data.id);
      if (exists) return;
      setNotifications([
        ...(notifications || []),
        { ...data, remove: clearNotification },
      ]);
    } catch (_) {
      return;
    }
  }, [message]);

  const clearNotification = async ({ id }: { id: string }) => {
    try {
      let data: { data: Array<IDataNotification> } = await api.patch(
        `/alert/${publicToken}/${id}`,
        { status: false }
      );

      setNotifications((prev) => {
        return prev?.filter((item) => item.id !== id && item);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getNotifications = async () => {
    try {
      let { data }: { data: Array<IDataNotification> } = await api.get(
        `/alert/${publicToken}`
      );
      if (!data || !data.length) return;
      data = data.map((item) => {
        return {
          ...item,
          remove: clearNotification,
        };
      });

      setNotifications(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [publicToken]);

  return <Notifications notifications={notifications} />;
};
