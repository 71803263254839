import styled from 'styled-components';
import { FontFamily } from './config';
import { ITypographyStyledProps } from './interfaces';

export const H1 = styled.h1<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(2.6rem, 3vw, 3.5rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const H2 = styled.h2<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(2rem, 2.4vw, 2.8rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const H3 = styled.h3<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(1.7rem, 1.8vw, 1.9rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const H4 = styled.h4<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(1.5rem, 1.6vw, 1.7rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const H5 = styled.h5<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(1.3rem, 1.4vw, 1.5rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const H6 = styled.h6<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(1.2rem, 1.3vw, 1.4rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;

export const P = styled.p<ITypographyStyledProps>`
    font-size: ${({ txtSize }) =>
        txtSize
            ? `clamp(${txtSize[0]}rem, ${txtSize[1]}vw, ${txtSize[2]}rem)`
            : 'clamp(1.2rem, 1.3vw, 1.4rem)'};

    font-family: ${FontFamily.fontFamily};
    font-weight: lighter;
`;
