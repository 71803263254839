import styled from "styled-components";
import { IConStyledProps } from "./interface";

export const IconContainer = styled.i<{ styled: IConStyledProps }>`
    
    color: ${({ styled }) => styled?.color ? styled?.color : '#ffff' };
    
    cursor: ${({ styled }) => styled?.cursor ? styled.cursor : 'initial'};

    font-size: ${({ styled }) => {
        switch (styled.size) {
            case '1x':
                return '1rem';
            case '1.5x':
                return '1.5rem';
            case '2x':
                return '2rem';
            case '2.5x':
                return '2.5rem';
            case '3x':
                return '3rem';
            case '3.5x':
                return '3.5rem';
            case '4x':
                return '4rem';
            case '4.5x':
                return '4.5rem';
            case '5x':
                return '5rem';
            case '5.5x':
                return '5.5rem';
            case '6x':
                return '6rem';
            case '6.5x':
                return '6.5rem';
            case '7x':
                return '7rem';
            case '7.5x':
                return '7.5rem';
            case '8x':
                return '8rem';
            case '8.5x':
                return '8.5rem';
            case '9x':
                return '9rem';
            case '9.5x':
                return '9r.5em';
            case '10x':
                return '10rem';
            default:
                return '1rem';
        }
    }};
    
`;
