import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  div {
    display: flex;
    align-items: center;

    flex-direction: column;
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 5px;
      font-size: 1.2rem;
      color: var(--color-grayDark);
    }
  }
`;

const StyledLogOutButton = styled.button`
  border: none;
  outline: none;
  background-color: var(--color-secondary);
  width: 100px;
  height: 25px;
  color: var(--color-white);
  font-size: 1rem;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
`;

export { StyledContainer, StyledLogOutButton };
