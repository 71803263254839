import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    
    position: fixed;
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerLoading = ({ children }: { children: React.ReactNode }) => {
    return (
        <Container style={{ zIndex: 999 }} >
            {children}
        </Container>
    )
};