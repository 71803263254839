import styled from "styled-components";
import { IStyledsProps } from "../NewTable/interfaces";

const EmptyBody = styled.div<{ emptyRows: number; headerLenght: number }>`
  height: ${({ emptyRows }) => emptyRows * 33 + "px"};
  width: ${({ headerLenght }) => headerLenght * 200 + "px"};
  background-color: white;
`;

const OverflowWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
`;

const TableHeaderCell = styled.div<{ styled?: IStyledsProps  }>`
  display: flex;
  width: ${({ styled }) => styled?.isRowRresentation ? '150px' : '100%'};
  max-width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};
  padding: ${({ styled }) => styled?.padding ? styled?.padding : '100%'};
  /* padding: 10px; */

  height: ${({ styled }) => styled?.isRowRresentation ? '55px' : ''};
  border-right: solid 1px #eaeaea;
  border-bottom: ${({ styled }) => styled?.isRowRresentation ? 'solid 1px #eaeaea' : ''};
  background-color: #558EFF40;
  
  color: #25282b;
  font-weight: bold;
`;

const TableBody = styled.div<{ styled?: IStyledsProps }>`
  display: flex;
  flex-direction: column;
  width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};
  min-height: 164px; /* defina uma altura fixa para a tabela */
  background-color: white;

  overflow-Y: scroll;
  overflow-X: hidden;

  max-height: 400px;

  ::-webkit-scrollbar-track {
    background-color: #F6F8FB;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: #F6F8FB;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #A0A4A8;
        border-radius: 5px;
    }
`;

const TableRow = styled.div`
  display: flex;

  :nth-of-type(even) {
    > div {
      background-color: white;
    }
  }

  :nth-of-type(odd) {
    > div {
      background-color: var(--color-secondary-light);
    }
  }
`;

const TableBodyCell = styled.div<{ styled?: { width?: number; }, isIcon?: boolean }>`
  flex: 1;
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;

  width: 100%;
  max-width: ${({ styled }) => styled?.width ? `${styled.width}px` : '100%'};

  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  
  align-items: flex-start;
  display: flex;
  justify-content: ${({ isIcon }) => isIcon ? 'center' : ''};


  button {
    border: none;
    background-color: transparent;
    text-decoration: underline;

    font-weight: bold;
    color: #25282b;
    padding: 0px;
  }

  a {
    cursor: pointer;

    font-size: 0.9rem;

    i {
      margin-left: 5px;
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
  align-items: end;
  
  border-top: solid 1px #eaeaea;
  border-radius: 0px 0px 15px 15px;

  background-color: #fff;
  padding: 20px;
`;

const Button = styled.button`
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  background: transparent;
  color: #000;
  cursor: pointer;

  &:disabled {
    /* background-color: #ccc; */
    color: #ccc;
    cursor: not-allowed;
  }
`;

const PageNumber = styled.div`
  margin: 2px;
`;

export { Button, EmptyBody, OverflowWrapper, PageNumber, Pagination, TableBody, TableBodyCell, TableHeader, TableHeaderCell, TableRow, TableWrapper };

