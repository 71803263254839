import styled from "styled-components";

const EmptyTableContainer = styled.div`
  height: 350px;
  width: 100%;
  background-color: #f6f6f6;
  color: #52575c;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 60px;
      height: 60px;

      opacity: 0.2;
      
      /* margin-bottom: 15px; */
    }

    p {
      text-align: center;
    }
  }

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export { EmptyTableContainer };

