import { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { TimeLineComponent } from "../../components/TimeLine";
import { useRequests } from "../../providers/Requests";
import { useAppSelector } from "../../store";
import { StyledContainer, StyledTimeLineContainer } from "./styles";

const TimeLine = () => {
  const { timeLineItems, getTimelineData, loading } = useRequests();

  const { patientToShow } = useAppSelector((state) => state.user);

  useEffect(() => {
    getTimelineData(patientToShow.mpiKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientToShow]);

  return (
    <>
      {!loading && (
        <StyledContainer>
          <StyledTimeLineContainer>
            {timeLineItems.length === 0 && (
              <h1
                style={{
                  position: "absolute",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "2rem",
                  padding: "30px 200px",
                  color: "#c3c3c3",
                }}
              >
                O paciente ainda não possui uma linha do tempo em nossa base de
                dados.
              </h1>
            )}
            <TimeLineComponent />
          </StyledTimeLineContainer>
        </StyledContainer>
      )}
      {loading && (
        <>
          <StyledContainer>
            <StyledTimeLineContainer>
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#CACCCF",
                    marginTop: "35px",
                  }}
                  animation="border"
                />
              </div>
            </StyledTimeLineContainer>
          </StyledContainer>
        </>
      )}
    </>
  );
};

export default TimeLine;
