import styled from "styled-components";
import { ITimeLineDotStyledProps } from "./interfaces";

export const Container = styled.div<ITimeLineDotStyledProps>`
    width: ${({ size }) => `${size}rem`};
    height: ${({ size }) => `${size}rem`};
    
    min-width: 1.5rem;
    min-height: 1.5rem;

    max-width: 5rem;
    max-height: 5rem;

    border-radius: 50%;
    
    background-color: ${({ color }) => color};
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        display: ${({ toogleToltip }) => toogleToltip ? 'initial' : 'none'};
        border: 15px solid transparent;
        border-right-color: #3a3a3a;
        content: "";
        bottom: -18px;
        rotate: 90deg;
        position: absolute;
        transition: all .1s;
    }
`;