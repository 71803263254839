import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  min-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;

  .wrap-infos {
    display: flex;
    justify-content: center;

    width: 100%;
    min-width: 95%;
  }
`;

const StyledImageContainer = styled.img`
  width: 120px;
  min-width: 100px;

  height: 120px;
  min-height: 100px;

  border-radius: 100%;
`;

const StyledPrimaryInfos = styled.div`
  display: flex;

  .infos {
    margin-right: 50px;

    h1 {
      color: var(--color-grayDark);
      font-weight: bold;

      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 1.1rem;
      letter-spacing: 0.1px;
    }

    .details {
      display: flex;

      p {
        color: var(--color-grayDark);

        letter-spacing: 0.1px;
      }

      .sex-text {
        margin-top: 10px;
      }

      .infos-subdiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 50px;

        /* Tablet */
        @media (min-width: 768px) {
          flex-wrap: wrap;
          .row-infos {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
`;

const StyledButton = styled.button<{ selected: boolean }>`
  border: none;
  background-color: transparent;
  color: ${({ selected }) => (selected ? "#558EFF" : "#52575C")};

  width: 150px;

  cursor: pointer;

  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1px;

  :hover {
    color: var(--color-secondary);
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;

  margin-top: 30px;
  margin-left: 130px;
  z-index: 99;
  width: 100%;
`;

const StyledSecondaryInfos = styled.div`
  color: var(--color-grayDark);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 300px;
  height: 100px;

  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: 0.2px;

  margin-top: 20px;
  margin-left: 15px;

  z-index: 0;

  .data {
    margin-bottom: 10px;
    p {
      margin-bottom: 5px;
    }
  }

  /* Tablet */
  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const ContainerNamePatient = styled.div`
  display: flex;

  width: 100%;

  padding: 10px 30px;

  margin-bottom: 5px;

  gap: 0 !important;

  h1 {
      font-weight: bold;
      font-size: 1.25rem;

    position: relative;

    span {
      background-color: red;
      position: absolute;
      color: white;
      font-weight: bold;
      font-size: .75rem;
      border-radius: 50%;
      padding: 5px 5px;
      top: -15px;
    }
  }

  p {
    font-size: 1rem;
  }

  @media (max-width: 810px) {
    align-items: flex-start;
    padding: 10px 0px;
  };

  @media (max-width: 580px) {
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  };
`;

const ContainerDataMobile = styled.div`
  width: 100%;
  height: 100%;

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  flex-direction: column;

  gap: 15px;
`;

const ContainerInfoUserMobile = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  text-align: center;

  gap: 10px;

  strong {
    font-size: 1rem;
  }
`;

const ContainerNamePatientMobile = styled.div`
  width: 100%;
  text-align: center;
  
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 1.4rem;
  }

  p {
    font-size: 1rem;
    display: flex;
  }
`;

const ContainerImageUser = styled.figure`
  width: 80px;
  height: 80px;

  object-fit: contain;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

export { ContainerDataMobile, ContainerImageUser, ContainerInfoUserMobile, ContainerNamePatientMobile, StyledButton, StyledButtonsContainer, StyledContainer, StyledImageContainer, StyledPrimaryInfos, StyledSecondaryInfos };

