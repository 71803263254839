import styled from 'styled-components';

export const SelectItemRoot = styled.div<{ backgroundRevert?: boolean }>`
    padding: 20px;
    height: 30px;
    border-radius: 10px;

    box-shadow: 1.5px 1.5px 3px #c3c3c350;

    background-color: ${({ backgroundRevert }) =>
        backgroundRevert ? '#f7f7f795' : '#ffffff'};
    border: solid 2px #dddddd;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;

    transition: all 0.3s;

    &:hover {
        border: ${({ backgroundRevert }) =>
            backgroundRevert ? 'solid 2px #3d79f290' : 'solid 2px #ffffff'};

        background-color: ${({ backgroundRevert }) =>
            backgroundRevert ? 'transparent' : '#3d79f2'};

        color: ${({ backgroundRevert }) =>
            backgroundRevert ? '#3d79f2' : '#ffffff'};
    }
`;
