import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiAuth } from "../../../../../services/apiAuth";
import { IAuthRefreshToken } from "../../interfaces";

export const refreshTokenThunk = createAsyncThunk(
  "Refresh Token",
  async (): Promise<IAuthRefreshToken> => {
    try {
      const { data } = await apiAuth.get('/auth/refresh')
      return data;
    } catch (error) {
      console.error(error);
      return { message: 'Erro interno do servidor', statusCode: 500 };
    }
  }
);
