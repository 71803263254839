import styled from "styled-components";
import { IStyledsProps } from "./interface";

export const TooltipBox = styled.div<{ styled: IStyledsProps }>`

  display: flex;
  flex-direction: ${({ styled: { positionInitial } }) => positionInitial ? positionInitial : 'row'};
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: row-reverse;
  };

  &:hover::after {
    opacity: 1;
    pointer-events: all;
  }

  &::after {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    white-space: normal;
    pointer-events: none;
    transition: .5s;
    opacity: 0;
    content: attr(aria-label);
    background: #222;
    color: #fff;
    width: auto;
    border-radius: 5px;
    
    padding: 5px 10px;
    margin: 30px;

    display: flex;
    
    @media (max-width: 550px) {
      margin-top: 20px;
    };

    z-index: 9999;
    font-size: .9rem;
    position: absolute;
  }
`;

