import styled from 'styled-components';

export const SelectRootContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 10px;
    border-radius: 10px;

    box-shadow: 3px 3px 5px #c3c3c395;
    background-color: #f3f3f3;

    border: solid 2px #d3d3d3;

    gap: 10px;

    width: 100%;
    max-width: 500px;
    min-width: 300px;
`;
