export interface IDataNotification {
  id: string;
  title: string;
  remove?: ({ id }: { id: string }) => void;
  content: string;
  date: Date;
  status: boolean;
  type: string;
}

export enum WsDatameaTypes {
  ALERT = "alert",
}

export type MessageNotificationWebSocket = {
  type: WsDatameaTypes;
  data: {
    id: string;
    title: string;
    content: string;
    date: Date;
    status: boolean;
    type: string;
  };
};

export interface INotification {
  notifications?: Array<IDataNotification>;
}
