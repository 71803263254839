import { useBoolean } from "@dataopera/dto-ui";
import { Skeleton } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useClientConfig } from "../../../../hooks/useClientConfig";
import { api } from "../../../../services/api";
import { useAppSelector } from "../../../../store";
import {
  IPatientProvider,
  IProviderUser,
  IProviders,
} from "../../../../store/slices/userAuthSlice/thunks/userLoginThunk/_mocks";
import { ISessionData } from "../../../../store/slices/userAuthSlice/thunks/userLoginThunk/interfaces";
import { scrollPage } from "../../../../utils/scrollPage";
import { Loading } from "../../../loading";
import { IMevoButtonProps, IMevoResponse } from "./interfaces";
import { ButtonStyled } from "./styled";

moment.locale("pt-br");

export const MevoButton = ({ setMevoUrl, setOpenPopup }: IMevoButtonProps) => {
  const { patientToShow } = useAppSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const isDoctor = useBoolean(false);
  const isLoadingRecipe = useBoolean(false);
  const isLoading = useBoolean(false);

  const [professionalData, setProfessionalData] = useState<
    IProviderUser | undefined
  >(undefined);
  const [patientData, setPatientData] = useState<IPatientProvider | undefined>(
    undefined
  );

  const { internalName } = useClientConfig();

  const authClient = JSON.parse(
    sessionStorage.getItem("@Auth:client") as string
  ) as ISessionData;

  const handleInit = useCallback(async () => {
    try {
      isLoading.onTrue();
      const { data: providers } = await api.get<IProviders>(
        `/providers/${authClient?.patient}/${authClient?.userid}`
      );

      if (providers?.professional && providers?.professional.PROVIDER_CODE) {
        setProfessionalData(providers.professional);
        isDoctor.onTrue();
      }

      if (providers?.patient && providers.patient.PATIENT_CODE) {
        setPatientData(providers.patient);
      }
      
      isLoading.onFalse();
    } catch (error) {
      console.error(error);
    }
  }, [authClient?.patient, authClient?.userid, isDoctor]);

  const handleClickButton = async () => {
    isLoadingRecipe.onTrue();
    
    const dataRequest = {
      Medico: {
        Nome: professionalData?.PROVIDER_NAME || authClient.username,
        ReferenciaExterna: authClient.userid,
        Documento: professionalData?.PROVIDER_PRIMARY_DOCUMENT,
        RegistroProfissional: {
          Numero: professionalData?.PROVIDER_REGISTER,
          Conselho: professionalData?.PROVIDER_REGISTER_NAME,
          UF: professionalData?.PROVIDER_REGISTER_UF,
        },
        TipoDocumento: "CPF",
        // TelefoneCelular: "11998959898",
        // Email: "eduardo+doutor@email.com.br",
        // CNS: "CNS do médico",
        // Especialidades: ["Clínica Geral", "Cirurgia Geral"],
        // Endereco: {
        //   Endereco1: "Rua Steven Gerrard",
        //   Endereco2: "8",
        //   Bairro: "Ídolo",
        //   Cidade: "São Paulo",
        //   Estado: "SP",
        //   CodigoPostal: "29142676",
        // },
      },
      Paciente: {
        ReferenciaExterna: patientData?.PATIENT_CODE || patientToShow["id-origin"],
        Nome: patientData?.PATIENT_NAME || patientToShow.nome,
        Nascimento: moment(patientData?.PATIENT_BIRTH_DATE || patientToShow?.["data-nascimento"]).format(
          "YYYY/MM/DD"
        ),
        Sexo: patientData?.PATIENT_GENDER || patientToShow.genero,
        Documento: patientData?.PATIENT_PRIMARY_DOCUMENT || patientToShow["doc-cpf"],
        Etnia: patientData?.PATIENT_RACE || patientToShow["raca-cor"],
        TelefoneCelular: `${patientData?.PATIENT_DDD_PHONE || ''}${patientData?.PATIENT_PHONE}` || patientToShow["fone-celular"],
        Email: patientData?.PATIENT_EMAIL || '',
        Endereco: {
          Endereco1: patientData?.PATIENT_ADDRESS || patientToShow["end-logradouro"],
          Bairro: patientData?.PATIENT_NEIGHBORHOOD 
            ? patientData?.PATIENT_NEIGHBORHOOD 
            : patientToShow["end-bairro"],
          Cidade: patientData?.PATIENT_CITY || patientToShow["end-cidade"],
          Estado: patientData?.PATIENT_STATE || patientToShow["end-estado"],
          CodigoPostal: patientToShow["end-cep"]
            ? patientToShow["end-cep"]
            : patientData?.PATIENT_ZIPCODE,
        },
      },
    };

    try {
      const { data } = await api.post(
        `/summary/${internalName}/prescription`,
        dataRequest
      );

      isLoadingRecipe.onFalse();

      if (!data) console.error("Não foi possível gerar a URL dinâmica.");

      const modalURL = data.ModalURL as string;

      setMevoUrl(modalURL);
      setOpenPopup(true);
      scrollPage.disabled();

      window.addEventListener(
        "message",
        (event: MessageEvent<IMevoResponse>) => {
          if (event.data && event.data.PDFUrl) {
            setMevoUrl(event.data.PDFUrl);
          } else {
            setOpenPopup(false);
          }
        },
        false
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderButton = () => {
    if (isDoctor.value)
      return (
        <ButtonStyled
          style={{
            width: "125px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isLoadingRecipe.value ? 'transparent' : ''
          }}
          onClick={handleClickButton}
        >
          {isLoadingRecipe.value ? (
            <Loading
              size="small"
              backgroundColor="transparent"
              style={{ width: "auto"}}
              type="two"
              color="#558eff"
            />
          ) : (
            "Receita Digital"
          )}
        </ButtonStyled>
      );
    else
      return isLoading.value ? (
        <Skeleton
          sx={{
            width: "115px",
            height: "47px",
            borderRadius: "7px",
            fontSize: "14px",
            fontWeight: "bold",
            position: "relative",
            right: "45px",
          }}
        />
      ) : <></>
  };
  
  useEffect(() => {
    handleInit();
  }, []);

  return renderButton();
};
