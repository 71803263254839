import styled from "styled-components";
import { ISearchStyledProps } from "./interfaces";

export const Root = styled.div<ISearchStyledProps>`
    background-color: ${({ searchBgColor = 'transparent' }) => searchBgColor};
 
    width: ${({ searchSize = 'medium' }) => {
        switch (searchSize) {
            case 'full':
                return '100%'
            case 'small':
                return '15rem'
            case 'medium':
                return '20rem'
            case 'large':
                return '25rem'
            case 'large-2x':
                return '30rem'
        };
    }};

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 5px;

    border-radius: ${({ searchBdRadius = '1.5rem' }) => searchBdRadius};
    
    border: ${({ searchBd = 'solid 2px #4F8AE310' }) => searchBd};
    border: ${({ searchBdColor }) => searchBdColor ? searchBdColor : ''};
    border: ${({ searchBdSize }) => searchBdSize ? `${searchBdSize}rem` : ''};

    box-shadow: ${({ searchBxShadown = '.5px .5px .5px 1px #00000020' }) => searchBxShadown};
`;

export const Input = styled.input<ISearchStyledProps>`
    outline: none;
    border: none;

    color: ${({ searchTxtColor = '#4F8AE3'}) => searchTxtColor};
    font-size: ${({ searchTxtSize = .9 }) => `${searchTxtSize}rem`};

    display: flex;

    padding: 5px 0px 5px 15px;

    flex: 1;
    background-color: transparent;
`;

export const ContainerButtonOnSearch = styled.button<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    border: none;
    
    gap: 5px;

    padding: 5px 10px 5px 5px;

    cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};

    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;

    border-left: solid 2px #c3c3c3;
    background-color: ${({ disabled }) => disabled ? '#4f8ae380' : '#4f8ae3'};

    color: white;
`;