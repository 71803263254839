import styled from 'styled-components';
import { ITimeLineHeaderStyledProps } from './interfaces';

export const Container = styled.header<ITimeLineHeaderStyledProps>`
    width: 100%;
    padding: 10px 0px;
    gap: 10px;

    display: flex;
    align-items: center;
    
    justify-content: ${({ align }) => {
        switch (align) {
            case 'right':
                return 'flex-end';
            case 'left':
                return 'flex-start';
            case 'between':
                return 'space-between';
            default: 
                return 'center';
        }
    }};
`;