/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ModalWrapper from "../../../../components/TableComponents/Modal";
import { ModalBackdrop } from "../../../../components/TableComponents/Modal/style";
import ModalTable from "../../../../components/TableComponents/ModalTable";
import { NewBlankTable } from "../../../../components/TableComponents/NewBlankTable";
import { NewChartTable } from "../../../../components/TableComponents/NewChartTable";
import Table from "../../../../components/TableComponents/NewTable";
import RequestExam from "../../../../components/TableComponents/NewTable/RequestExam";
import { ContainerLoading } from "../../../../components/loading/ContainerLoading";
import * as Loading from "../../../../components/loading/components/";
import { useClientConfig } from "../../../../hooks/useClientConfig";
import { useRequests } from "../../../../providers/Requests";

import { Modal } from "@mui/material";
import {
  ChartItemInterface,
  RowsTypeInterface,
} from "../../../../providers/Requests/types";
import { api, shouldGoBackToLogin } from "../../../../services/api";
import { useAppSelector } from "../../../../store";
import { TableSkeletons } from "../TableSkeletons";

export interface ITableContainerProps {
  fontSize: string;
  tableColor: string;
}

interface TableContainerInt {
  isLoading?: boolean;
  isTimeLineView?: boolean;
  encounterId?: string;
  sx?: ITableContainerProps;
}

const Layout = styled.div`
  display: flex;
  margin-top: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;

  .card-target:target {
    > div {
      box-shadow: 0px 0px 4px 5px rgb(0 0 0 / 13%);
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* max-width: 1360px; */
  justify-content: space-around;
  gap: 40px 0px;
  margin: 0 auto;
`;

type OpenModalProps = {
  title: string;
  paramsData?: any;
  dataSrc: string;
};

export function TableContainer({
  isLoading,
  encounterId,
  isTimeLineView,
  sx,
}: TableContainerInt) {
  let { tableData } = useRequests();

  const omitTablesTimeLine = (src: string) => {
    const tablesOmit = ["encounter", "allergy", "condition"];

    return !!tablesOmit.find((tbl) => src.includes(tbl));
  };

  const [loading, setLoading] = useState(false);
  const [loadedItemsCount, setLoadedItemsCount] = useState(0);

  const [toogleExamResult, setToogleExamResult] = useState(false);
  const [toogleFilePreview, setToogleFilePreview] = useState(false);
  const [fileData, setFileData] = useState<string>();
  const [loadingFile, setLoadingFile] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [scheduleModal, setScheduleModal] = useState(false);
  const [cell, setCell] = useState<RowsTypeInterface>({} as RowsTypeInterface);

  const { patientToShow } = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  async function handleGetData(dataSrc: string, paramsData?: any) {
    try {
      const req = await api.get(
        `/${dataSrc}/${patientToShow.mpiKey}/${paramsData || ""}`
      );

      return req;
    } catch (error) {
      console.error(error);
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        sessionStorage.clear();
        navigate("/auth/login");
      }
      setModalData(null);
      setModal(false);
    }
  }

  async function handleGetCustomData(dataSrc: string, paramsData?: any) {
    try {
      const req = await api.get(`/${dataSrc}/${patientToShow.mpiKey}`, {
        params: paramsData,
      });

      return req;
    } catch (error) {
      console.error(error);
      const goBack = shouldGoBackToLogin(error);
      if (goBack) {
        sessionStorage.clear();
        navigate("/auth/login");
      }
      setModalData(null);
      setModal(false);
    }
  }

  async function openModal({ title, dataSrc, paramsData }: OpenModalProps) {
    document.body.classList.remove("nav-down");
    document.body.classList.add("nav-up");
    document.body.classList.add("no-scroll");
    setLoading(true);

    const req = await api.get(
      `/${dataSrc}/${patientToShow.mpiKey}/${paramsData || ""}`
    );

    // if (req?.data) req.data = MockTableVerticalData;

    setModalData({ title, data: req?.data });
    setLoading(false);
    setModal(true);
  }

  const handleDownloadFile = async (link: string) => {
    setLoadingFile(true);
    setToogleFilePreview(true);

    if (link.includes("download-file")) {
      const { data, headers } = await axios.get(`${link}&typeView=inline`, {
        withCredentials: true,
        responseType: "arraybuffer",
      });

      const blob = new Blob([data], { type: String(headers["content-type"]) });
      const url = window.URL.createObjectURL(blob);

      setFileData(url);
    } else setFileData(link);

    setLoadingFile(false);
  };

  async function handleOpenExamResult(references: any, title: string) {
    document.body.classList.remove("nav-down");
    document.body.classList.add("nav-up");
    document.body.classList.add("no-scroll");

    setLoading(true);

    const req = await handleGetCustomData("summary/observation", {
      detail: true,
      references,
    });

    setModalData({ title: `Resultado do Exame - ${title}`, data: req?.data });
    setLoading(false);
    setToogleExamResult(true);
  }

  function closeModal() {
    document.body.classList.remove("no-scroll");
    document.body.classList.remove("nav-up");
    setModal(false);
    setToogleExamResult(false);
    setToogleFilePreview(false);
    setModalData({});
  }

  const handleOpenScheduleModal = (cell: RowsTypeInterface) => {
    setScheduleModal(true);
    setCell(cell);
  };

  const handleCloseScheduleModal = () => {
    setScheduleModal(false);
  };

  // const toogleClientConfig =
  //   process.env.REACT_APP_COMPANY === "DTOCompany" ||
  //   process.env.REACT_APP_COMPANY === "PocFirstCompany"
  //     ? ClientConfig
  //     : ClientConfigTWO;
  
  const { contentSlots, headerSlots, logo } = useClientConfig();

  return (
    <>
      {loading && (
        <>
          <ModalBackdrop style={{ zIndex: 999 }} />
          <ContainerLoading>
            <Loading.LoadingFour size="medium" color="#ffff" />
          </ContainerLoading>
        </>
      )}
      <Layout>    
        
            {isTimeLineView && (loadedItemsCount  < tableData.length) &&             
                  
                  <TableSkeletons  />                              
            } 
            
                                
            
            <CardsContainer style={{display: `${ !isTimeLineView || loadedItemsCount  >= tableData.length ?  "flex" :  "none"}`}} >
            {tableData.map((table, key) => (
              <React.Fragment key={key}>
                {
                  <>
                    {table.isChart ? (
                      <NewChartTable
                        iconType={
                          contentSlots.filter(
                            (content) => table.title === content.title
                          )[0].iconType
                        }
                        req={table.req}
                        openModal={() =>
                          openModal({
                            title:
                              contentSlots.filter(
                                (content) => table.title === content.title
                              )[0].title || "Pacientes",
                            dataSrc: "summary/encounter",
                          })
                        }
                        data={table.data as ChartItemInterface[]}
                        subtitle={
                          contentSlots.filter(
                            (content) => table.title === content.title
                          )[0].subtitle || "Pacientes"
                        }
                        title={
                          contentSlots
                            .filter((content) => table.title === content.title)[0]
                            .title.toUpperCase() || "Pacientes"
                        }
                        position={table.position}
                      />
                    ) : table.isBlank ? (
                      <NewBlankTable
                        isFull={
                          contentSlots.filter(
                            (content) => table.title === content.title
                          )[0].isFull
                        }
                        position={table.position}
                        key={key}
                      />
                    ) : (
                      <Table
                        req={table.req}
                        encounterId={encounterId}
                        key={key}
                        isTimeLineView={isTimeLineView}
                        subtitle={table.subtitle}
                        isFull={
                          isTimeLineView || 
                          contentSlots.filter(
                            (content) => table.title === content.title
                          )[0].isFull
                        }
                        isRowRresentation={
                          contentSlots.filter(
                            (content) =>
                              table.title === content.title &&
                              !!content.isRowRresentation
                          )[0]?.isRowRresentation
                        }
                        positionOnLayout={table.position}
                        iconType={
                          contentSlots.filter(
                            (content) => table.title === content.title
                          )[0].iconType
                        }
                        openModal={() =>
                          openModal({
                            title: table.title,
                            dataSrc: table.dataSrc,
                            paramsData: table.req.tableCode,
                          })
                        }
                        handleOpenScheduleModal={handleOpenScheduleModal}
                        handleOpenExamResult={handleOpenExamResult}
                        handleDownloadFile={handleDownloadFile}
                        setLoadingFile={setLoadingFile}
                        title={table.title.toUpperCase()}
                        header={table.headers}
                        rows={table.rows}
                        sx={sx}
                        setLoadedItemsCount={setLoadedItemsCount}
                      />
                    )}
                  </>
                }
              </React.Fragment>
            ))}
          </CardsContainer>
    
        
       
      </Layout>

      {toogleFilePreview && (
        <Modal open={toogleFilePreview} onClose={closeModal}>
          <ModalWrapper
            iconType="file-alt"
            title={"PRÉ VISUALIZAÇÃO DO ARQUIVO"}
            closeModal={closeModal}
          >
            <div
              style={{
                width: "100%",
                height: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loadingFile ? (
                <Loading.LoadingOne size="big" color="#558eff" />
              ) : (
                <embed
                  src={fileData}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </ModalWrapper>
        </Modal>
      )}

      {modal && (
        <Modal open={modal} onClose={closeModal}>
          <ModalWrapper
            iconType={
              contentSlots.filter(
                (content) => modalData.title === content.title
              )[0].iconType
            }
            title={modalData.title.toUpperCase()}
            closeModal={closeModal}
          >
            <ModalTable
              title={modalData.title}
              iconType={
                contentSlots.filter(
                  (content) => modalData.title === content.title
                )[0].iconType
              }
              typePagination="Pagination"
              isRowRresentation={
                contentSlots.filter(
                  (content) =>
                    modalData.title === content.title &&
                    !!content.isRowRresentation
                )[0]?.isRowRresentation
              }
              handleOpenScheduleModal={handleOpenScheduleModal}
              header={modalData.data?.headers}
              rows={modalData.data?.rows}
            />
          </ModalWrapper>
        </Modal>
      )}

      {toogleExamResult && (
        <Modal open={toogleExamResult} onClose={closeModal}>
          <ModalWrapper
            iconType="poll-h"
            title={modalData.title.toUpperCase()}
            closeModal={closeModal}
          >
            <ModalTable
              iconType="poll-h"
              title={modalData.title}
              handleOpenScheduleModal={handleOpenScheduleModal}
              header={modalData.data?.headers}
              rows={modalData.data?.rows}
              isUniqueDate
              typePagination="VirtualScrolling"
            />
          </ModalWrapper>
        </Modal>
      )}

      {scheduleModal && (
        <Modal open={scheduleModal} onClose={closeModal}>
          <ModalWrapper
            iconType={"calendar-alt"}
            title={"Solicitação de agendamento"}
            closeModal={handleCloseScheduleModal}
          >
            <RequestExam
              code={cell.value || ""}
              closeFunc={handleCloseScheduleModal}
              exam={cell.exam || "Exame"}
            />
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
}
