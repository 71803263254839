import styled from "styled-components";

const StyledContainer = styled.div`
  background-color: var(--color-background);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;

  .sides-container {
    display: flex;
    justify-content: center;
    @media (max-width: 760px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .sides-container-in {
    display: flex;
    justify-content: center;
    @media (max-width: 760px) {
      flex-direction: column;
      align-items: center;
      height: 700px;
      overflow-y: scroll;
      justify-content: flex-start;
    }
  }

  .children-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media (max-width: 760px) {
      width: 100%;
      justify-content: center;
      > div {
        width: 100%;
        margin-right: 10px;
      }
    }
    .MuiTextField-root {
      width: 50%;
    }

    .exam-name {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 700;
      text-align: center;
    }
  }
  .MuiTextField-root {
    margin-left: 10px;

    @media (max-width: 760px) {
      margin-left: 0px !important;
    }
  }

  .left-side {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 30px;

    @media (max-width: 760px) {
      width: 60%;
    }
  }

  .select-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 1.1rem;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .error-message {
      color: var(--color-red);
    }
  }

  .right-side {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .labs-container {
    height: 350px;
    max-height: 350px;
    overflow-x: scroll;
    padding: 5px;

    @media (max-width: 760px) {
      height: auto;

      > div {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
    }
  }
`;

const StyledInput = styled.input`
  width: 250px;
  border-radius: 5px;

  font-size: 0.8rem;
  color: "#25282B";

  background-color: #fff;
  padding: 10px;
  border: none;
  outline: none;

  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 10%);
`;

const StyledTextBox = styled.textarea`
  width: 250px;
  border-radius: 5px;

  height: 80px;
  resize: none;

  font-size: 0.8rem;
  color: "#25282B";

  background-color: #fff;
  padding: 10px;
  border: none;
  outline: none;

  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 10%);
`;

const StyledLabItem = styled.div<{ active: boolean }>`
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 15px;
  margin-bottom: 5px;

  border-radius: 5px;
  background-color: ${(props) => (props.active ? "#c5c5c5" : "#fff")};
  color: #000;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 10%);
  cursor: pointer;

  :hover {
    background-color: ${(props) => (props.active ? "#e5e5e5" : "#e5e5e5")};
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  padding: 15px;

  margin-top: 10px;
  border-top: 1px solid #e5e5e5;

  button {
    border: none;
    width: 200px;
    @media (max-width: 760px) {
      width: 90%;
    }
    padding: 15px;
  }

  .btn-success {
    margin-left: 20px;
    background-color: var(--color-secondary);
    color: var(--color-white);

    :hover {
      background-color: #3074fb;
    }
  }

  .btn-danger {
    background-color: var(--color-red);
    color: var(--color-white);

    :hover {
      background-color: #f64e46;
    }
  }
`;

export { StyledContainer, StyledInput, StyledLabItem, StyledButtonContainer, StyledTextBox };
