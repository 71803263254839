import { Button, ListItemText, Modal, Tooltip } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import moment from "moment";
import { useState } from "react";
import { Icon } from "../../icon";
import { Loading } from "../../loading";
import { IDataNotification } from "../interfaces";
import { ISettingsModalProps } from "./interfaces";
import * as S from "./styled";

export const DetailModal = ({
  onClose,
  isOpen,
  notification,
}: ISettingsModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async (notify: IDataNotification) => {
    setLoading(true);

    if (!notify.remove) return;

    await notify.remove({ id: notify.id });

    onClose();
    setLoading(false);
  };

  return (
    <Modal
      key={notification?.id}
      style={{ outline: "none" }}
      onClose={onClose}
      open={isOpen}
      sx={S.ModalStyled}
    >
      <List
        style={S.ListStyled}
        subheader={
          <ListSubheader sx={S.ListHeader}>
            <ListItemText
              primary={
                <h2 style={{ fontSize: "1.2rem" }}>{notification?.title}</h2>
              }
              secondary={
                <p style={{ color: "#d3daff" }}>
                  {notification?.date
                    ? moment(notification?.date).format("DD/MM/YYYY")
                    : ""}
                </p>
              }
            />
            <Icon
              type="solid"
              handleClick={onClose}
              cursor="pointer"
              icon="close"
            />
          </ListSubheader>
        }
      >
        <ListItem sx={{ height: 120 }}>
          <ListItemText primary={notification?.content} />
        </ListItem>

        <ListItem style={{ justifyContent: "flex-end" }}>
          {loading ? (
            <div style={{ width: 30, height: 20, marginRight: "20px" }}>
              <Loading
                backgroundColor="none"
                style={{ position: "initial" }}
                size="medium"
                type="five"
                color="#558eff"
              />
            </div>
          ) : (
            <Tooltip title="Ao clicar em 'Remover' a notifação atual será removida.">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  if (notification) handleRemove(notification);
                }}
              >
                Remover
              </Button>
            </Tooltip>
          )}
        </ListItem>
      </List>
    </Modal>
  );
};
