import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/Auth";
import { useAppDispatch, useAppSelector } from "../../store";
import { userLogoutThunk } from "../../store/slices/userAuthSlice/thunks/logoutThunk";
import { ContainerComponent } from "../Container";
import { Input } from "./components/Input";
import { HelpModal } from "./components/modalHelp";
// import { NotificationIcon } from "./components/Notifications";
import { Tooltip } from "@mui/material";
import { useClientConfig } from "../../hooks/useClientConfig";
import { scrollPage } from "../../utils/scrollPage";
import { Icon } from "../icon";
import { Popup } from "../popup";
import { NotificationIcon } from "./components/Notifications";
import { MevoButton } from "./components/mevoButton";
import { SettingsModal } from "./components/settingsModal";
import { StyledIcon } from "./style";
import "./styles.css";

export const Header = () => {
  const [helpModal, setHelpModal] = useState<boolean>(false);
  const navigation = useNavigate();

  const { logo, mevoDigitalRecipe } = useClientConfig();

  const logoutIcon = require("../../assets/images/logoutBlack.png");

  const scrollDirection = useScrollDirection();
  const { handleSearchUser, handleLogOut } = useAuth();

  const { isDoctor, patientToShow } = useAppSelector((state) => state.user);

  const [openPopup, setOpenPopup] = useState(false);
  const [mevoUrl, setMevoUrl] = useState("");

  const handleSearch = (value: string) => {
    handleSearchUser(value);
  };

  const dispatch = useAppDispatch();

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState<null | string>(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const handleClose = () => {
    setHelpModal(false);
    return;
  };

  useEffect(() => {
    if (scrollDirection === "up" || !scrollDirection) {
      document.body.classList.remove("nav-up");
      document.body.classList.add("nav-down");
    } else {
      document.body.classList.remove("nav-down");
      document.body.classList.add("nav-up");
    }
  }, [scrollDirection]);

  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const onCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };

  return (
    <>
      {openPopup && (
        <Popup.Root align="center" style={{ width: "100%", height: "100%" }}>
          <Popup.Header hg={3}>
            <Tooltip title="Fechar">
              <Icon
                cursor="pointer"
                handleClick={() => {
                  setOpenPopup(false);
                  scrollPage.enabled();
                }}
                icon="times"
                type="solid"
              />
            </Tooltip>
          </Popup.Header>
          <Popup.Content bdRadius="0px 0px 8px 8px" style={{ padding: 0 }}>
            <iframe
              src={mevoUrl}
              width="100%"
              style={{ borderRadius: "0px 0px 8px 8px", padding: 0 }}
              height="100%"
              title="Receita Mevo"
            />
          </Popup.Content>
        </Popup.Root>
      )}
      <header className="header-component">
        <ContainerComponent>
          <div className="header-content">
            <div className="logo-tools-header-area">
              <figure
                style={{ cursor: !isDoctor ? "pointer" : "initial" }}
                className="main-logo-header-area"
              >
                <img
                  style={{ width: logo.size }}
                  className="main-logo-header"
                  onClick={() => (!isDoctor ? navigation("/") : "")}
                  src={logo.url}
                  alt="Logo DataMea"
                />
              </figure>
              {patientToShow && (
                <section className="tools-header-area">
                  {mevoDigitalRecipe && (
                    <MevoButton
                      setOpenPopup={setOpenPopup}
                      setMevoUrl={setMevoUrl}
                    />
                  )}

                  <Tooltip title="Configurações do Sistema">
                    <div
                      style={{
                        width: "40px",
                      }}
                    >
                      <Icon
                        icon="gear"
                        type="regular"
                        cursor="pointer"
                        handleClick={() => setOpenSettingsModal(true)}
                        color="black"
                      />
                    </div>
                  </Tooltip>

                  {/* { !isDoctor && <img src={searchIcon} alt="Search" />} */}
                  <Tooltip title="Ajuda">
                    <StyledIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setHelpModal(true)}
                      className="far fa-question-circle"
                    />
                  </Tooltip>
                  <article>
                    <NotificationIcon />
                  </article>
                  <Tooltip title="Sair" >
                    <img
                      src={logoutIcon}
                      alt="Logout"
                      onClick={() => {
                        handleLogOut();
                        dispatch(userLogoutThunk());
                      }}
                    />
                  </Tooltip>
                </section>
              )}
            </div>

            {isDoctor && (
              <section className="search-header-area">
                <Input
                  placeholder="Pesquise por um paciente"
                  onSearch={handleSearch}
                  type="text"
                />
              </section>
            )}
          </div>
        </ContainerComponent>
      </header>

      <HelpModal isOpen={helpModal} onClose={() => setHelpModal(false)} />

      <SettingsModal
        isOpen={openSettingsModal}
        onClose={onCloseSettingsModal}
      />
    </>
  );
};
