import { ISectionRootProps } from './interfaces';
import * as S from './styled';

export const SectionRoot = ({
    children,
    sectionFooter,
    loadingOpacity = 1,
    ...rest
}: ISectionRootProps) => {
    return (
        <>
            <S.SectionRoot {...rest}>{children}</S.SectionRoot>
            { loadingOpacity === 1 && (sectionFooter)} 
        </>
    );
};
