import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import { DevPage } from "../pages/Dev";
import LoginPage from "../pages/Login";
import { AuthClient } from "./middlewares/authClient";
import { PrivateRoute } from "./middlewares/authentication";
import { HandleApiKey } from "./middlewares/handleApiKey";
import { RedirectAuth } from "./middlewares/redirectAuth";

const Router = () => {
  return (
    <Routes>
      <Route path="/dev" element={<DevPage />} />
      
      <Route path=":code" element={<RedirectAuth />} />
      <Route path="/auth/:client" element={<RedirectAuth />} />

      <Route path="/auth/apikey" element={<HandleApiKey />} />
      <Route path="/auth/client" element={<AuthClient />} />
      <Route path="/auth/login" element={<LoginPage />} />
      
      <Route element={<PrivateRoute />} >
        <Route path="/" element={<Dashboard />} />
      </Route>

    </Routes>
  );
};

export default Router;