import styled from "styled-components";

const SubtitlesContainer = styled.div`
  background-color: white;
  width: 100%;

  min-width: 90%;
  > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;

    .color {
      display: flex;
      align-items: center;
      height: 30px;
      margin: 5px;

      font-size: 1rem;
      span {
        width: 10px;
        height: 10px;
        display: block;

        margin-right: 5px;
        
        border: 1px solid black;
      }
    }
  }

  display: flex;

  .subtitle {
    margin: 10px;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const GraphWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  svg {
    width: 45%;
  }
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;

  gap: 5px;

  height: 100%;
  
  align-items: center;

  i {
    padding-bottom: 10px;
    font-size: 1rem;
  }
`;

export { ContainerTitle, GraphWrapper, SubtitlesContainer, TitleContainer };

