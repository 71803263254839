import styled from "styled-components";

export const DescriptionCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
`;

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical; 
`;

export const LerMais = styled.p`
  color: #00000050;
  line-height: 16px;

  &:hover {
    color: #2B9AF4;
    cursor: pointer;
    border-bottom: 1px solid #2B9AF4;
  }
`;
