import { SectionContent } from './SectionContent';
import { SectionFooter } from './SectionFooter';
import { SectionHeader } from './SectionHeader';
import { SectionRoot } from './SectionRoot';

export const Section = {
    Root: SectionRoot,
    Header: SectionHeader,
    Content: SectionContent,
    Footer: SectionFooter,
};
