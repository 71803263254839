import { } from '@dataopera/dto-ui';
import ListUsersCard from "../ListUsersCard";
import { StyledListUsersModalContainer, StyledListUsersModalHeader } from "./style";

interface SearchedUsers {
  birth: string;
  cpf: string;
  mother: string;
  name: string;
  publicToken: string;
  sex: string;
}

interface ListUsersInterface {
  searchedUsers: SearchedUsers[];
  selectUserCallBack: (userToken: string) => void;
  onCloseCallback: () => void;
}

export default function ListUsersModal({ searchedUsers, selectUserCallBack, onCloseCallback }: ListUsersInterface) {
  function handleSelectUser(token: string) {
    selectUserCallBack(token);
  }

  return (
    <StyledListUsersModalContainer className="listUsers-modal">
      <StyledListUsersModalHeader className="listUsers-modal_header">
        <h1 className="listUsers-modal_title">Pacientes</h1>
        <div className="listUsers-modal_closeModal">
          <i onClick={() => onCloseCallback()} className="fas fa-times" />
        </div>
      </StyledListUsersModalHeader>

      <div className="listUsers-modal_patients">
        {searchedUsers?.map((elm, ind) => (
          <ListUsersCard user={elm} onClick={handleSelectUser} key={ind} />
        ))}
      </div>
    </StyledListUsersModalContainer>
  );
}
