import styled from 'styled-components';
import { ISectionFooterStyledProps } from './interfaces';

export const SectionFooter = styled.footer<ISectionFooterStyledProps>`
    width: 100%;
    padding: 4px;
    height: auto;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    background-color: ${({ bgColor }) => bgColor};

    justify-content: ${({ align }) => {
        switch (align) {
            case 'left':
                return 'flex-start';
            case 'right':
                return 'flex-end';
            default:
                return 'center';
        }
    }};

    padding: ${({ pf, px, py }) => {
        if (pf) return `${pf}rem`;
        else if (px) return `${px}rem 0`;
        else if (py) return `0 ${py}rem`;
        else return '0';
    }};

    padding-top: ${({ pt }) => (pt ? `${pt}rem` : '')};
    padding-left: ${({ pl }) => (pl ? `${pl}rem` : '')};
    padding-bottom: ${({ pb }) => (pb ? `${pb}rem` : '')};
    padding-right: ${({ pr }) => (pr ? `${pr}rem` : '')};

    margin: ${({ mf, mx, my }) => {
        if (mf) return `${mf}rem`;
        else if (mx) return `${mx}rem 0`;
        else if (my) return `0 ${my}rem`;
    }};

    margin-top: ${({ mt }) => (mt ? `${mt}rem` : '')};
    margin-left: ${({ ml }) => (ml ? `${ml}rem` : '')};
    margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : '')};
    margin-right: ${({ mr }) => (mr ? `${mr}rem` : '')};
`;
