import styled from "styled-components";

const StyledContainer = styled.header`
  /* Mobile first */
  width: 100%;
  height: 180px;

  background-color: var(--color-background);

  position: sticky;
  top: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    cursor: pointer;
    width: 120px;
  }

  .divisor {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Tablet */
    @media (min-width: 768px) {
      flex-direction: row;
      img {
        margin-right: 30px;
        margin-left: 30px;
      }
    }

    /* LapTop */
    @media (min-width: 1024px) {
      img {
        margin-right: 80px;
        margin-left: 80px;
      }
    }

    /* LapTop Big */
    @media (min-width: 1024px) {
      img {
        margin-right: 120px;
        margin-left: 120px;
      }
    }

    img {
      margin-bottom: 10px;
    }
    .styled-input {
      margin-bottom: 10px;
    }
  }
  .divisor-icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    /* Tablet */
    @media (min-width: 768px) {
      width: auto;
      justify-content: center;

      i {
        margin: 0px 10px;
      }
    }

    i {
      cursor: pointer;

      font-size: 22px;

      color: var(--color-grayLight);
    }
  }

  /* Tablet */
  @media (min-width: 768px) {
    height: 90px;

    flex-direction: row;
    justify-content: space-around;

    .divisor-icons {
      i {
        cursor: pointer;

        font-size: 22px;

        color: var(--color-grayLight);
      }
    }
  }
`;

const StyledIcon = styled.i`
  :hover {
    transform: scale(1.2);
  }
`;

const StyledLogOutButton = styled.button`
  margin-left: 10px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
`;

export { StyledContainer, StyledIcon, StyledLogOutButton };
