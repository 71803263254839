import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptyTableContainer } from "./style";

export default function EmptyTable({ title }: { title: string }) {
  return (
    <EmptyTableContainer id={title}>
      <div>
        <FontAwesomeIcon icon={faFolderOpen} />
        <p>Não há <strong>{title}</strong> para exibir.</p>
      </div>
    </EmptyTableContainer>
  );
}
