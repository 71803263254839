import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiAuth } from "../../../../../services/apiAuth";
import { IAuthResponse } from "../../interfaces";

export const userLogoutThunk = createAsyncThunk('userLogout', async (): Promise<boolean> => {

    try {
        await apiAuth.get(`auth/logout`) as { data: IAuthResponse };
        return true;
    } catch (error) {
        console.error(error);
        return false
    };

}); 