import styled from "styled-components";

export const ButtonStyled = styled.button`
    outline: none;
    border: none;
    
    padding: 4px 7px;
    border: solid 2px #558eff;

    border-radius: 5px;

    font-size: 15px;

    text-shadow: 0.1px 0.1px .5px #c3c3c3;
    
    box-shadow: 2px 2px 4px #c3c3c360;
    background-color: #c3c3c320;

    color: #558eff;
    font-weight: bold;

    transition: all .4s;

    position: relative;

    right: 45px;

    &:hover {
        background-color: #558eff;
        color: #ffffff;
    }
`;