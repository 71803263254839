import styled from "styled-components";
import { ITimeLineScrollBarStyledProps } from "./interfaces";

export const ScrollBar = styled.div<ITimeLineScrollBarStyledProps>`
    width: 100%;
    
    overflow-x: scroll;
    overflow-y: hidden;

    display: flex;
    align-items: center;
    
    gap: 10px;

    ::-webkit-scrollbar {
        display: ${({ hasScroll }) => hasScroll ? 'initial' : 'none'};
        height: 5px;
    }
`;