import { motion } from 'framer-motion';
import { IExpansionOpacityAnimationProps } from './interfaces';

export const ExpansionOpacityAnimation = ({
    children,
    duration = 0.8,
    delay = 0.5,
    type = 'default',
    hg = 'auto',
}: IExpansionOpacityAnimationProps) => {
    const animationDirectionInit = () => {
        switch (type) {
            case 'default':
                return { opacity: 0, scale: 0 };
            case 'horizontal':
                return { opacity: 0, scaleX: 0 };
            case 'vertical':
                return { opacity: 0, scaleY: 0 };
        }
    };

    const animationDirectionEnd = () => {
        switch (type) {
            case 'default':
                return { opacity: 1, scale: 1 };
            case 'horizontal':
                return { opacity: 1, scaleX: 1 };
            case 'vertical':
                return { opacity: 1, scaleY: 1 };
        }
    };

    return (
        <motion.div
            className="box"
            initial={animationDirectionInit()}
            animate={animationDirectionEnd()}
            transition={{
                duration,
                delay,
                ease: [0, 0.71, 0.2, 1.01],
            }}
            style={{ height: hg }}
        >
            {children}
        </motion.div>
    );
};
