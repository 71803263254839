import styled from 'styled-components';
import { ISectionContentStyledProps } from './interfaces';

export const SectionContent = styled.div<ISectionContentStyledProps>`
    flex: ${({ flex }) => flex || 0};

    width: ${({ wt }) => (wt ? `${wt}%` : '100%')};
    height: ${({ hg }) => (hg ? `${hg}vh` : 'auto')};

    display: flex;
    flex-wrap: wrap;

    gap: ${({ gap }) => (gap ? `${gap}rem` : '3rem')};

    flex-direction: ${({ align }) => {
        switch (align?.direction) {
            case 'row':
                return 'row';
            case 'row-reverse':
                return 'row-reverse';
            case 'column-reverse':
                return 'column-reverse';
            case 'column':
                return 'column';
            default:
                return 'row';
        }
    }};

    align-items: ${({ align }) => {
        switch (align?.position) {
            case 'left':
                return 'flex-start';
            case 'right':
                return 'flex-end';
            case 'center':
                return 'center';
        }
    }};

    justify-content: ${({ align }) => {
        switch (align?.spacing) {
            case 'evenly':
                return 'space-evenly';
            case 'between':
                return 'space-between';
            case 'around':
                return 'space-around';
            case 'center':
                return 'center';
            default:
                return 'space-around';
        }
    }};

    padding: ${({ pf, px, py }) => {
        if (pf) return `${pf}rem`;
        else if (px) return `${px}rem 0`;
        else if (py) return `0 ${py}rem`;
        else return '0rem';
    }};

    padding-top: ${({ pt }) => (pt ? `${pt}rem` : '')};
    padding-left: ${({ pl }) => (pl ? `${pl}rem` : '')};
    padding-bottom: ${({ pb }) => (pb ? `${pb}rem` : '')};
    padding-right: ${({ pr }) => (pr ? `${pr}rem` : '')};

    margin: ${({ mf, mx, my }) => {
        if (mf) return `${mf}rem`;
        else if (mx) return `${mx}rem 0`;
        else if (my) return `0 ${my}rem`;
        else return '0';
    }};

    margin-top: ${({ mt }) => (mt ? `${mt}rem` : '')};
    margin-left: ${({ ml }) => (ml ? `${ml}rem` : '')};
    margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : '')};
    margin-right: ${({ mr }) => (mr ? `${mr}rem` : '')};
`;
