import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "../../../pages/loading";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openPopup } from "../../../store/slices/userAuthSlice";
import { IAuthResponse } from "../../../store/slices/userAuthSlice/interfaces";
import { userLoginThunk } from "../../../store/slices/userAuthSlice/thunks";

export const HandleApiKey: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading } = useAppSelector((state) => state.user);
  const code = JSON.parse(sessionStorage.getItem("@Auth:code") as string);

  const handleLogin = async () => {
    const {
      payload: { type, message },
    } = (await dispatch(userLoginThunk({ apiKey: code as string }))) as {
      payload: IAuthResponse;
    };

    if (type === "authentication-error") {
      console.error(message);
      navigate("/auth/login");
    }

    if (type === "unknown-error") {
      console.error(message);
      navigate("/server-error");
    }

    if (type === "patient-not-found") {
      dispatch(
        openPopup({
          title: "Paciente não localizado",
          message,
        })
      );
      navigate("/");
    }

    if (type === "invalid-api-key") {
      dispatch(
        openPopup({
          title: "Token de Autenticação inválido",
          message,
        })
      );
    }

    if (type === "consentment-denied") {
      dispatch(
        openPopup({
          title: "Paciente sem Consentimento!",
          message,
        })
      );
      navigate("/");
    }

    navigate("/");
  };

  useEffect(() => {
    handleLogin();

    if (code) sessionStorage.setItem("@code", code);
  }, [code]);

  return <>{loading ? <LoadingPage /> : ""}</>;
};
