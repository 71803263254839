import { Box, Skeleton } from "@mui/material";

const InfosCardSkeleton = () => {
  return (
    <Skeleton width={255} height={150} style={{ marginTop: "-35px" }}>
      <Box className="iconDiv">
        <Box />
      </Box>
      <Box className="ifonsDiv">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Box>
    </Skeleton>
  );
};

export { InfosCardSkeleton };
