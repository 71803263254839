import axios from "axios";
import { useState } from "react";
import { useClientConfig } from "../../../hooks/useClientConfig";
import {
  IconTypesTable,
  RowsTypeInterface,
} from "../../../providers/Requests/types";
import { capitalizeText } from "../../../utils/capitalizeText";
import { Tooltip } from "../../Tooltip";
import { Icon } from "../../icon";
import EmptyTable from "../EmptyTable";
import { DescriptionCell } from "../NewTable/components/cells/descriptionCell";
import { ContainerHeaderTableRows } from "../NewTable/style";
import {
  Button,
  OverflowWrapper,
  PageNumber,
  Pagination,
  TableBody,
  TableBodyCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from "./style";

type Row = {
  type: string;
  value: string;
  width?: number;
  exam?: string;
  label?: string;
};



type Props = {
  header: Array<{ type: string; value: string; width: number; label?: string }>;
  rows: Row[][];
  typePagination: "VirtualScrolling" | "Pagination";
  isRowRresentation?: boolean;
  handleOpenScheduleModal: (cell: RowsTypeInterface) => void;
  title: string;
  iconType: IconTypesTable;
  isUniqueDate?: boolean;
};

const renderTableRows = (
  rows: Row[],
  handleOpenScheduleModal: (cell: RowsTypeInterface) => void,
  isRowRresentation?: boolean,
  ItemsPerPage?: number,
  indexLine?: number,
  headerDataTable?: {
    iconType: IconTypesTable;
    title: string;
    subtitle: string;
  }
) => {
  if (isRowRresentation) {
    for (
      let index = rows.length;
      index <= (ItemsPerPage ? ItemsPerPage : rows.length);
      index++
    ) {
      if (index === (ItemsPerPage ? ItemsPerPage : rows.length)) {
        rows.push({ type: "text", value: "" });
      }
    }
  }

  if (isRowRresentation) {
    rows = rows.map((row) => {
      if (row.type === "blank") row.type = "text";
      return row;
    });
  }

  const handleDownload = async (link: string) => {
    if (!link.startsWith(process.env.REACT_APP_API_URL as string)) {
      return window.open(link);
    }
    const response = await axios.get(link, {
      responseType: "arraybuffer",
      withCredentials: true,
    });

    const blob = await response.data;
    const url = window.URL.createObjectURL(blob);

    window.open(url);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { examSchedule } = useClientConfig();

  return rows.map((cell, index) => (
    <>
      {cell.type === "text" && (
        <TableBodyCell
          styled={{ width: cell.width }}
          style={{
            minWidth: "120px",
            borderRight: "solid 1px #eaeaea",
            display: isRowRresentation ? "flex" : "",
            justifyContent: isRowRresentation ? "center" : "",
          }}
          key={index}
        >
          <>
            {isRowRresentation ? (
              indexLine === 0 ? (
                <Tooltip description={cell.label as string}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "14.5px",
                      height: "35px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      // width: cell?.width != null ? cell.width : "5px",
                      justifyContent: "center",
                      flexDirection:
                        (cell.label?.length as number) > 2 ? "column" : "row",
                    }}
                  >
                    {capitalizeText(cell.value)}
                  </p>
                </Tooltip>
              ) : (
                <p
                  style={{
                    gap: "2.5px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    height: "35px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: cell?.width != null ? cell.width : "5px",
                    flexDirection:
                      (cell.label?.length as number) > 2 ? "column" : "row",
                  }}
                >
                  {capitalizeText(cell.value)}
                  {(cell.label?.length as number) > 2 ? (
                    <span style={{ fontSize: "11px" }}>
                      {capitalizeText(cell.label as string)}
                    </span>
                  ) : (
                    <span style={{ fontSize: "12px", marginLeft: "2px" }}>
                      {capitalizeText(cell.label as string)}
                    </span>
                  )}
                </p>
              )
            ) : (
              <>
                {cell.value.length > 100 ? (
                  <DescriptionCell
                    headerDataTable={headerDataTable}
                    row={rows}
                  />
                ) : (
                  <p>{capitalizeText(cell.value)} </p>
                )}
              </>
            )}
          </>
        </TableBodyCell>
      )}
      {cell.type === "exam-schedule" && examSchedule && (
        <TableBodyCell styled={{ width: cell.width }} key={index} isIcon={true}>
          <Tooltip
            positionInitial="row-reverse"
            description="Solicitação de Agendamento"
          >
            <Icon
              cursor="pointer"
              size="1.5x"
              handleClick={() => handleOpenScheduleModal(cell)}
              color="#c3c3c3"
              type="solid"
              icon="calendar-clock"
            />
          </Tooltip>
        </TableBodyCell>
      )}
      {cell.type === "link" && (
        <TableBodyCell styled={{ width: cell.width }} isIcon={true} key={index}>
          <a onClick={() => handleDownload(cell.value)} rel="noreferrer">
            <Tooltip positionInitial="row-reverse" description="Ver Docuemento">
              <Icon
                cursor="pointer"
                customStyleds={{ fontSize: "1.20rem" }}
                color="#558eff"
                type="solid"
                icon="link"
              />
            </Tooltip>
          </a>
        </TableBodyCell>
      )}
      {cell.type === "exam-result" && (
        <TableBodyCell styled={{ width: cell.width }} isIcon={true} key={index}>
          <a onClick={() => handleDownload(cell.value)} rel="noreferrer">
            <Tooltip
              positionInitial="row-reverse"
              description="Resultado do Exame (PDF)"
            >
              <Icon
                cursor="pointer"
                customStyleds={{ fontSize: "1.30rem" }}
                color="#558eff"
                type="solid"
                icon="file-pdf"
              />
            </Tooltip>
          </a>
        </TableBodyCell>
      )}
      {cell.type === "blank" && null}
    </>
  ));
};

export default function ModalTable({
  header,
  rows,
  typePagination,
  isRowRresentation,
  handleOpenScheduleModal,
  isUniqueDate,
  title,
  iconType,
}: Props) {
  const [currentPage, setCurrentPage] = useState(0);

  const WidthTable = window.document.body.clientWidth;
  let ItemsPerPage = 0;

  if (isRowRresentation && rows !== undefined && rows[0] !== undefined) {
    let somaDasRows = 0;

    rows[0].forEach((row) => {
      if (row.width) somaDasRows = somaDasRows + row.width;
    });

    ItemsPerPage = WidthTable / (somaDasRows / rows[0].length);
  }

  const startIndex = currentPage * (isRowRresentation ? ItemsPerPage : 5);
  const endIndex = startIndex + (isRowRresentation ? ItemsPerPage : 5);

  let rowsToShow: Row[][] = [];

  if (rows)
    rowsToShow = isRowRresentation
      ? rows.map((row) => row.slice(startIndex, endIndex))
      : rows.slice(startIndex, endIndex);
  else rowsToShow = [];

  const totalPages = Math.ceil(
    isRowRresentation ? rows[0]?.length / 10 : rows?.length / 5
  );

  const handlePrevClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const headerDataTable = {
    iconType,
    title,
    subtitle: "Clique para ver mais detalhes",
  };

  header = [...header, { type: "text", value: "", width: 1 }];

  const rowsPagination = {
    VirtualScrolling: rows,
    Pagination: rowsToShow,
  };

  rows = rowsPagination[typePagination];

  const validateDate = (date: any) => {
    const regex = new RegExp(
      "^(0[1-9]|[12][0-9]|3[01])[\\/](0[1-9]|1[012])[\\/]\\d{4}$"
    );

    if (regex.test(date)) return true;
    else return false;
  };

  let uniqueDate: Row = { type: "", value: "Não Informada", label: "Data:" };

  if (isUniqueDate) {
    header = header.filter((item) => item.value !== "Data");
    rows = rows.map((row) =>
      row.filter((item) => {
        if (!item.value || item.value.split(" ").length < 2 || !validateDate(item.value.split(" ")[0])) {
          return item;
        } else uniqueDate = item;
      })
    );
  }

  return (
    <>
      <TableWrapper>
        {(rows?.length === undefined || rows?.length <= 0) && (
          <EmptyTable title={title} />
        )}

        {rows?.length >= 1 && (
          <>
            <OverflowWrapper>
              {!isRowRresentation ? (
                <>
                  <TableHeader style={{ borderBottom: "1px solid #ffffff" }}>
                    <TableHeaderCell
                      styled={{
                        padding: "10px",
                      }}
                    >
                      <div style={{ display: "flex", gap: 10, alignItems: 'center', height: '100%', }} >
                        <h2 style={{ fontWeight: 'bold', fontSize: "1.03rem" }}>
                          {uniqueDate.label}
                        </h2>
                        <p style={{ fontSize: ".9rem" }}>
                          {uniqueDate.value}
                        </p>
                      </div>
                    </TableHeaderCell>
                  </TableHeader>
                  <TableHeader>
                    {header.map((column, index) => (
                      <TableHeaderCell
                        styled={{
                          width: column.width,
                          padding: column.value !== "" ? "10px" : "3.5px",
                        }}
                        key={index}
                      >
                        {column.value}
                      </TableHeaderCell>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        {renderTableRows(
                          row,
                          handleOpenScheduleModal,
                          undefined,
                          undefined,
                          undefined,
                          headerDataTable
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ) : (
                <div style={{ display: "flex" }}>
                  <ContainerHeaderTableRows>
                    {header.map((column, index) => (
                      <div>
                        <TableHeaderCell
                          styled={{ width: column.width, isRowRresentation }}
                          style={{ display: "flex", alignItems: "center" }}
                          key={index}
                        >
                          <Tooltip description={column.label as string}>
                            {column.value}
                          </Tooltip>
                        </TableHeaderCell>
                      </div>
                    ))}
                  </ContainerHeaderTableRows>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        style={{
                          backgroundColor: index === 0 ? "#558EFF35" : "",
                        }}
                        key={index}
                      >
                        {renderTableRows(
                          row,
                          handleOpenScheduleModal,
                          isRowRresentation,
                          ItemsPerPage,
                          index,
                          headerDataTable
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </div>
              )}
            </OverflowWrapper>

            {typePagination === "Pagination" && (
              <Pagination>
                <Button
                  disabled={currentPage + 1 === 1}
                  onClick={handlePrevClick}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
                <PageNumber>
                  {currentPage + 1} de {totalPages}
                </PageNumber>
                <Button
                  disabled={currentPage + 1 === totalPages}
                  onClick={handleNextClick}
                >
                  <i className="fas fa-chevron-right" />
                </Button>
              </Pagination>
            )}
          </>
        )}
      </TableWrapper>
    </>
  );
}
